import React, { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';
import Routes from '@services/Routes';
import { formValueSelector, getFormValues, change, reset } from 'redux-form';
import { connect, useStore } from 'react-redux';
import { ConfigType, LogoPositionType, TempType, UserType, setUser } from '@store/actions';
import { checkUser } from '@utils/reduxUtils';
import { RootState } from '@store/reducers';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import TagManager from 'react-gtm-module';
import { ModalManager } from 'react-dynamic-modal';
import { ProcessingModal } from '@components/_shared/Modals/Modals';
import { apiService } from '@services/api.service';
import { backendUrl } from '@utils/platformBasedInfo';
import InfoContainer from '@components/Apply/Info';
import {
  ApplyWizardStep,
  ApplyWizardStepObject,
  STEP_AMOUNT,
  STEP_BUSINESS_FURTHER_DETAILS,
  STEP_BUSINESS_MORE_DETAILS,
  STEP_BUSINESS_OWNERS,
  STEP_HOMEPAGE,
  STEP_BCM_HOMEPAGE,
  STEP_INFO,
  STEP_SUMMARY,
  STEP_TYPE_OF_FUNDING,
  STEP_VERIFY_LOGIN,
  STEP_WHEN_FUNDING_NEEDED,
  STEP_BCM_INFO,
  STEP_BCM_BUSINESS_MORE_DETAILS,
  STEP_BCM_SUMMARY,
  STEP_INTEGRATED_LOGIN,
} from '@components/ApplyWizard/ApplyWizardSteps';
import { setFarthestStep as setFarthestStepAction } from '../redux/actions';
import { HomeWrapped } from '@components/Home';
import AmountContainer from '@components/Apply/Amount';
import Auth0CallbackContainer from '@components/Apply/Auth0';
import { MoreDetailsOnYourBusiness } from '@components/Apply/MoreDetailsOnYourBusiness/MoreDetailsOnYourBusiness';
import { FurtherBusinessDetails } from '@components/Apply/FurtherBusinessDetails/FurtherBusinessDetails';
import { OwnersMoreDetailsAboutYou } from '@components/Apply/OwnersMoreDetailsAboutYou/OwnersMoreDetailsAboutYou';
import { FundingIsAlmostHere } from '@components/Apply/FundingIsAlmostHere/FundingIsAlmostHere';
import { TypeOfFunding } from '@components/Apply/TypeOfFunding/TypeOfFunding';
import { WhenFundingNeeded } from '@components/Apply/WhenFundingNeeded/WhenFundingNeeded';
import { BCMMoreDetailsOnYourBusiness } from '@components/Apply/MoreDetailsOnYourBusiness/BCMMoreDetailsOnYourBusiness';
import { BCMSummary } from '@components/Apply/BCMSummary/BCMSummary';
import BCMHome from '@components/BCM/BCMHome';
import { ApplyWizardStepsWithComponents } from '@components/ApplyWizard/ApplyWizardStepsWithComponents';

export type ApplyWizardContextType = Partial<{
  applicationFlow: 'apply' | 'bcm';
  nextRoute: () => void;
  previousRoute: () => void;
  callApi: (route: string, data: any, vendor_n: string, options?: any) => Promise<any>;
  dataLayerPush: (event: any, post: any) => void;
  saving: boolean;
  setSaving: (saving: boolean) => void;
  openAlert: (message: string, level?: string, processing?: boolean, showFeedback?: boolean) => void;
  closeAlert: (event?: any, reason?: string) => void;
  alertOpen: boolean;
  alertMsg: string;
  alertLevel: string;
  alertProcessing: boolean;
  alertFeedbackLink: boolean;
  steps: ApplyWizardStepObject[];
  currentStep: ApplyWizardStepObject;
  currentStepIndex: number;
  showProcessingModal: (title: string, msg?: null | string) => void;
  changeStepTo: (step: ApplyWizardStep) => void;
  resetFarthestStep: () => void;
  changeStepToFirstNotValid: () => void;
  changeStepToFirst: () => void;
  farthestStep: ApplyWizardStepObject;
  isInWizard: boolean;
  showStepNavigation: boolean;
  currentPath: string;
  setCurrentPath: (path: string) => void;
  changeStepAfterLogin: () => void;
  setStepDirection: (direction: 'next' | 'prev') => void;
  stepDirection: 'next' | 'prev';
}>;

export const ApplyWizardContext = createContext<ApplyWizardContextType>({});

const a = [
  '/',
  'amount',
  'info',
  'business/more-details',
  'business/further-details',
  'type-of-funding',
  'business/owners',
  'when-funding-needed',
  'funding-is-almost-here',
];

export const ApplyWizardProviderConnected = ({
  bcmWizardRoutes = ['/bcm', '/bcm/info', '/bcm/business/more-details', '/bcm/app/contact-soon'],
  applicationType,
  children,
  routes,
  vendor_name,
  business_id,
  loan_id,
  contact_id,
  loan_type,
  loan_terms,
  allValues,
  theme,
  config,
  temp,
  user,
  images,
  partnerCustomFields,
  match,
  location,
  history,
  finalWizardRoutes,
  farthestStep,
  withAuth0ExternalUniversalLogin = false,
}: RouteComponentProps<{
  bcmWizardRoutes?: ApplyWizardStep[];
  farthestStep: ApplyWizardStepObject;
  children?: ReactNode;
  routes: string[];
  finalWizardRoutes: ApplyWizardStep[];
  vendor_name: string;
  business_id: string;
  loan_id: string;
  contact_id: string;
  loan_type: string;
  loan_terms: number;
  allValues: any;
  theme: {
    primary: string;
    primaryRgba: string;
    secondary: string;
    logoScale: number;
    name: string;
    palette?: {
      error?: { dark: string };
      primary?: { dark: string };
    };
    logoPosition: LogoPositionType;
  };
  config: ConfigType;
  temp: TempType;
  user: UserType['user'];
  images: any;
  partnerCustomFields: { [a: string]: string };
  withCustomLogin?: boolean;
}>) => {
  const store = useStore();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  // There is a bug that when some loan page is opened directly and BCM was set before then it might pickup BCM flow instead of loan. Monkeypatch here ensures that if it is opened at "/" in iframe/popup it does not happen.
  // Loan Homepage will reset applicationType to "apply" in Redux.
  const applicationTypeDetermined =
    currentPath === '/' ? 'apply' : applicationType === 'bcm' || currentPath.startsWith('/bcm') ? 'bcm' : 'apply'; // Apply is a standard flow, sometimes BCM might be set for /bcm route.
  const [steps, setSteps] = useState<ApplyWizardStepObject[]>([
    ...finalWizardRoutes
      .filter((r: ApplyWizardStep) => !!ApplyWizardStepsWithComponents[r])
      .map((r: ApplyWizardStep) => ApplyWizardStepsWithComponents[r]),
    ...(finalWizardRoutes.includes(STEP_VERIFY_LOGIN) ? [] : [ApplyWizardStepsWithComponents[STEP_VERIFY_LOGIN]]),
    ...(withAuth0ExternalUniversalLogin ? [] : [ApplyWizardStepsWithComponents[STEP_INTEGRATED_LOGIN]]),
  ]);
  const foundStepBasedOnPath = (ApplyWizardStepsWithComponents as any)[
    Object.keys(ApplyWizardStepsWithComponents).find(
      (key) =>
        (ApplyWizardStepsWithComponents as any)[key] &&
        (ApplyWizardStepsWithComponents as any)[key].path === currentPath
    ) ?? ''
  ];
  const [currentStep, setCurrentStep] = useState<ApplyWizardStepObject>(
    foundStepBasedOnPath
      ? foundStepBasedOnPath
      : currentPath === '/app/verify/login'
      ? { ...ApplyWizardStepsWithComponents[STEP_VERIFY_LOGIN] }
      : undefined
  );
  // console.log('currentStep', currentStep);
  // console.log('steps', steps);
  // console.log('finalWizardRoutes', finalWizardRoutes);
  const currentStepIndex = currentStep
    ? steps.findIndex((step) => step.id === currentStep.id)
    : steps.findIndex((step) => step.path === currentPath);
  const isInWizard = !!foundStepBasedOnPath;

  // console.log(
  //   'applicationTypeDetermined',
  //   foundStepBasedOnPath,
  //   applicationTypeDetermined,
  //   currentStep,
  //   currentPath,
  //   currentStepIndex
  // );

  useEffect(() => {
    if (applicationTypeDetermined === 'bcm') {
      const newSteps: ApplyWizardStepObject[] = bcmWizardRoutes
        .filter((r: ApplyWizardStep) => !!ApplyWizardStepsWithComponents[r])
        .map((r: ApplyWizardStep) => ApplyWizardStepsWithComponents[r]);
      setSteps([
        ...newSteps,
        ...(newSteps.some((r) => r.id === STEP_VERIFY_LOGIN)
          ? []
          : [ApplyWizardStepsWithComponents[STEP_VERIFY_LOGIN]]),
        ...(withAuth0ExternalUniversalLogin ? [] : [ApplyWizardStepsWithComponents[STEP_INTEGRATED_LOGIN]]),
      ]);
    }
    if (applicationTypeDetermined === 'apply') {
      const newSteps: ApplyWizardStepObject[] = finalWizardRoutes
        .filter((r: ApplyWizardStep) => !!ApplyWizardStepsWithComponents[r])
        .map((r: ApplyWizardStep) => ApplyWizardStepsWithComponents[r]);
      setSteps([
        ...newSteps,
        ...(newSteps.some((r) => r.id === STEP_VERIFY_LOGIN)
          ? []
          : [ApplyWizardStepsWithComponents[STEP_VERIFY_LOGIN]]),
        ...(withAuth0ExternalUniversalLogin ? [] : [ApplyWizardStepsWithComponents[STEP_INTEGRATED_LOGIN]]),
      ]);
    }
    // console.log('useEffect: ', {
    //   applicationTypeDetermined,
    //   currentPath,
    //   foundStepBasedOnPath,
    // });
    setCurrentStep(foundStepBasedOnPath ?? undefined);
  }, [applicationTypeDetermined]);

  const [stepDirection, setStepDirection] = useState<'next' | 'prev'>('next');
  const showStepNavigation = steps.some((step) => step.path === currentPath && step.isShowingInNavigation);
  const [saving, setSaving] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [alertLevel, setAlertLevel] = useState('warning');
  const [alertProcessing, setAlertProcessing] = useState(false);
  const [alertFeedbackLink, setAlertFeedbackLink] = useState(false);
  const { logout } = useAuth0();
  const dispatch = useDispatch();

  const changeStepTo = (step: ApplyWizardStep) => {
    const newStep = steps.find((s) => s.id === step);
    console.log('changeStepTo step', step, newStep);
    if (newStep) {
      const newStepIndex = steps.findIndex((s) => s.id === step);
      const farthestStepIndex = steps.findIndex((s) => s.id === farthestStep.id);
      if (farthestStepIndex < newStepIndex || farthestStepIndex < 0) {
        dispatch(setFarthestStepAction(newStep));
      }
      setCurrentStep(newStep);
      console.log('CALLBACK newStep', newStep);
      setCurrentPath(newStep.path);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (typeof window !== 'undefined' && !queryParams.get('code')) {
      window.history.pushState(null, '', currentPath);
    }
  }, [currentPath]);

  useEffect(() => {
    if (location.pathname) {
      setCurrentPath(location.pathname);
    }
  }, [location]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handlePopState = () => {
        setCurrentPath(location.pathname);
      };

      window.addEventListener('popstate', handlePopState);

      return () => {
        window.removeEventListener('popstate', handlePopState);
      };
    }
  }, []);

  const changeStepToFirst = () => {
    changeStepTo(steps[0].id !== STEP_HOMEPAGE && steps[0].id !== STEP_BCM_HOMEPAGE ? steps[0].id : steps[1].id);
  };

  const changeStepToFirstNotValid = () => {
    const latestValues = getFormValues('application')(store.getState() as RootState) || {};
    let firstNotValidStep = null;
    for (let i = 0; i < steps.length; i++) {
      try {
        steps[i].zodSchema.parse(latestValues);
      } catch (error) {
        firstNotValidStep = steps[i];
        break;
      }
    }

    changeStepTo(firstNotValidStep ? firstNotValidStep.id : STEP_SUMMARY);
  };

  const changeStepAfterLogin = () => {
    if (applicationTypeDetermined === 'bcm') {
      const infoIndex = steps.findIndex((step) => step.id === ApplyWizardStepsWithComponents[STEP_BCM_INFO].id);
      if (infoIndex === -1) {
        changeStepTo(steps[0].id);
      } else {
        if (infoIndex + 1 >= steps.length) {
          changeStepTo(steps[steps.length - 1].id);
        } else {
          changeStepTo(steps[infoIndex + 1].id);
        }
      }
    } else {
      const infoIndex = steps.findIndex((step) => step.id === ApplyWizardStepsWithComponents[STEP_INFO].id);
      if (infoIndex === -1) {
        changeStepTo(steps[0].id);
      } else {
        if (infoIndex + 1 >= steps.length) {
          changeStepTo(steps[steps.length - 1].id);
        } else {
          changeStepTo(steps[infoIndex + 1].id);
        }
      }
    }
  };

  const resetFarthestStep = () => {
    const stepsWithCompletion = steps.map((step) => ({
      ...step,
      isComplete: step.isComplete(allValues),
    }));
    let latestCompletedStep = null;
    for (let i = 0; i < stepsWithCompletion.length; i++) {
      if (stepsWithCompletion[i].isComplete) {
        latestCompletedStep = steps[i];
      } else {
        break;
      }
    }

    if (latestCompletedStep) {
      dispatch(setFarthestStepAction(latestCompletedStep));
    } else {
      dispatch(
        setFarthestStepAction(
          applicationTypeDetermined === 'bcm'
            ? { ...ApplyWizardStepsWithComponents[STEP_BCM_HOMEPAGE] }
            : { ...ApplyWizardStepsWithComponents[STEP_HOMEPAGE] }
        )
      );
    }
  };

  useEffect(() => {
    if (foundStepBasedOnPath) {
      setCurrentStep(
        foundStepBasedOnPath
          ? foundStepBasedOnPath
          : currentPath === '/app/verify/login'
          ? { ...ApplyWizardStepsWithComponents[STEP_VERIFY_LOGIN] }
          : undefined
      );
    }
  }, [foundStepBasedOnPath]);

  useEffect(() => {
    checkUser(user, match, dispatch, history, logout);
  }, []);

  const closeAlert = (event?: any, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };

  const openAlert = (message: string, level = 'warning', processing = false, showFeedback = true) => {
    setAlertOpen(true);
    setAlertMsg(message);
    setAlertLevel(level);
    setAlertProcessing(processing);
    setAlertFeedbackLink(showFeedback);
  };

  const dataLayerPush = (event: any, post: any) => {
    // shallow copy the post object
    let data = { ...post };

    // clear out any blacklisted/sensitive fields
    Object.keys(data).forEach(function (key) {
      if (/code|dob|ssn|bank/.test(key)) {
        delete data[key];
      }
    });

    const dataLayerObj = Object.assign({ event: event }, data);

    TagManager.dataLayer({
      dataLayer: {
        ...dataLayerObj,
      },
    });
  };

  const showProcessingModal = (title: string, msg: string | null = null) => {
    ModalManager.open(
      <ProcessingModal title={title} msg={msg} theme={theme} onRequestClose={() => false} images={images} />
    );
  };

  // WARNING!!! IF YOU ARE WRITING NEW CODE PLEASE DO NOT USE callAPi, INSTEAD USE:
  // - useAxios from src/js/api/axiosInstance.js
  // - example of use in src/js/components/Apply/Auth0/index.js
  const callApi = async (
    route: string,
    data: any,
    vendor_n: string,
    { method = 'POST', baseUrl, replaceEntirePost = false } = {
      method: 'post',
      baseUrl: backendUrl,
      replaceEntirePost: false,
    }
  ) => {
    // check if user login is expired
    checkUser(user, match, dispatch, history, logout);

    setSaving(true);

    // merge in always sent data properties
    const post = {
      account_id: business_id,
      contact_id: contact_id,
      vendor_id: config.id,
      ...data,
      source: {
        partnerId: config.id,
        ...data?.source,
      },
      business: {
        id: business_id,
        ...data?.business,
        contacts: data?.business?.contacts ?? data?.owners ?? [],
      },
    };

    const response = await apiService.call(route, replaceEntirePost ? data : post, vendor_name, user, {
      method,
      baseUrl,
    });

    if (response.status === 401) {
      history.push('/app/user/expired');
      return response;
    }
    if (response.error) {
      // handle expired token
      if (response.msg && response.msg === 'Unauthorized') {
        history.push('/app/user/expired');
        return response;
      }

      // handle caught errors
      const message = response.msg || `Oops, there was an error on our side. Code: 1`;
      const level = response.level || 'warning';
      const feedback = response.feedback || false;

      openAlert(message, level, false, feedback);
    }

    setSaving(false);

    return response;
  };

  const nextRoute = () => {
    closeAlert();
    console.log('currentStep', currentStep);
    if (applicationTypeDetermined === 'bcm' && currentStep.id === STEP_BCM_SUMMARY) {
      history.push('/bcm/app/contact-soon');
    } else {
      const currentStepIndex = steps.findIndex((r) => r.id === currentStep.id);
      console.log('currentStepIndex', currentStepIndex);
      console.log('next step is:', steps[currentStepIndex + 1]);
      setStepDirection('next');
      if (currentStep.id === STEP_SUMMARY) {
        history.push('/app/decision/process');
      } else {
        changeStepTo(steps[currentStepIndex + 1].id);
      }
    }
  };

  const previousRoute = () => {
    closeAlert();
    const currentStepIndex = steps.findIndex((r) => r.id === currentStep.id);
    setStepDirection('prev');
    if (currentStepIndex === 0) {
    } else {
      changeStepTo(steps[currentStepIndex - 1].id);
    }
  };

  return (
    <ApplyWizardContext.Provider
      value={{
        applicationFlow: applicationTypeDetermined,
        nextRoute,
        previousRoute,
        callApi,
        dataLayerPush,
        showProcessingModal,
        currentStepIndex,
        saving,
        setSaving,
        openAlert,
        closeAlert,
        alertOpen,
        alertMsg,
        alertLevel,
        alertProcessing,
        alertFeedbackLink,
        steps,
        currentStep,
        changeStepTo,
        changeStepToFirstNotValid,
        resetFarthestStep,
        changeStepToFirst,
        farthestStep,
        isInWizard,
        showStepNavigation,
        currentPath,
        setCurrentPath,
        changeStepAfterLogin,
        setStepDirection,
        stepDirection,
      }}
    >
      {children}
    </ApplyWizardContext.Provider>
  );
};

const selector = formValueSelector('application');

const mapStateToProps = (state: RootState) => {
  // attempt to ensure business_id is set
  const bizId = selector(state, 'business_id');
  const acctId = bizId ? bizId : state.user.accountId;

  return {
    farthestStep: state.wizardContext.farthestStep,
    routes: state.brand?.data?.routes,
    finalWizardRoutes: state.wizardRouting.finalWizardRoutes,
    vendor_name: state.brand?.data?.vendor_name,
    business_id: acctId,
    applicationType: selector(state, 'application_type'),
    loan_id: selector(state, 'loan_id'),
    contact_id: selector(state, 'owner_1_id'),
    loan_type: selector(state, 'loan_type'),
    loan_terms: selector(state, 'loan_terms'),
    allValues: getFormValues('application')(state) || {},
    theme: state.theme,
    config: state.config,
    temp: state.temp,
    user: state.user,
    images: state.brand?.data?.media,
    partnerCustomFields: state.partnerCustomFields,
  };
};

export const ApplyWizardProvider = withRouter(
  connect(mapStateToProps, { setUser, change })(ApplyWizardProviderConnected)
);

export const useApplyWizardContext = () => {
  const context = useContext(ApplyWizardContext);
  return context;
};
