import queryString from 'query-string';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { flowSteps, hideSteps, prefillSteps, removeFormParams } from '../prefillAndHideSteps';
import { useState } from 'react';
import { formValueSelector } from 'redux-form';
import { addRoutesToHide } from '../redux/actions';
import { useAuth0 } from '@auth0/auth0-react';
import { Loader, useIsInIframe } from 'shared-components';
import { useApplyWizardContext } from '../context/ApplyWizardContext';

const PrefillStepsWrapper = ({ children, config }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!config.loaded) return;
    (async () => {
      setLoading(true);
      const query = queryString.parse(location.search);
      if (Object.keys(query).length > 0) {
        await prefillSteps(query, dispatch, config, history);
      }
      setLoading(false);
    })();
  }, [config.loaded]);

  if (loading) {
    return (
      <div className="flex flex-1 items-center">
        <Loader />
      </div>
    );
  }

  return children;
};

const LoadStepsToHideFromGetParamsWrapper = ({ children, formValues }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (location && location.search) {
        const query = queryString.parse(location.search);
        if (query.stepsToHide) {
          const stepsToHideString = query.stepsToHide;
          try {
            JSON.parse(decodeURIComponent(stepsToHideString));
          } catch (error) {
            setLoading(false);
            setError('Invalid url');
            history.replace(location.pathname);
          }
          const stepsToHide = JSON.parse(decodeURIComponent(stepsToHideString));
          dispatch(addRoutesToHide({ routes: stepsToHide, data: formValues }));

          removeFormParams(query, ['stepsToHide'], history);
        }
      }
      setLoading(false);
    })();
  }, []);

  if (loading) {
    return <p>Preparing the form...</p>;
  }

  if (error && !loading) {
    alert('Error: ' + error);
  }

  return children;
};

const HideStepsIfAny = ({
  children,
  routes,
  user,
  callApi,
  dataLayerPush,
  vendor_name,
  routesToHide: _routesToHide,
  finalInAppValues,
  loanId,
}) => {
  const isOpenedInIframe = useIsInIframe();
  const { changeStepTo } = useApplyWizardContext();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const auth0 = useAuth0();
  const routesToHide = _routesToHide?.routes;
  const routesData = _routesToHide?.data;
  const pathsToHide = (routesToHide ?? [])
    .filter((idToHide) => !!flowSteps.find((route) => route.id === idToHide))
    .map((idToHide) => flowSteps.find((route) => route.id === idToHide))
    .map((flowStep) => flowStep.path);
  const hasStepsToHide =
    pathsToHide && pathsToHide.length > 0 && pathsToHide.some((pathToHide) => location.pathname.includes(pathToHide)); // Only trigger on routes that potentially should be hidden. On all others it should not be run
  const [loading, setLoading] = useState(hasStepsToHide ? true : false);
  const [error, setError] = useState(false);
  const [lastTriggeredOnPath, setLastTriggeredOnPath] = useState(null);

  useEffect(() => {
    (async () => {
      if (hasStepsToHide && location.pathname !== lastTriggeredOnPath) {
        setLastTriggeredOnPath(lastTriggeredOnPath);
        setLoading(true);
        const updatedRoutes = await hideSteps({
          dispatch,
          stepsToHide: routesToHide,
          routes,
          formValues: routesData,
          finalInAppValues,
          user,
          callApi,
          dataLayerPush,
          vendor_name,
          loanId,
          auth0,
          history,
          isOpenedInIframe,
          changeStepTo,
        });

        // We confirmed user landed on a hidden route so let's navigate to the first available one after the calculations.
        history.push(`/app/${updatedRoutes[0]}`);

        setLoading(false);
      }
    })();
  }, [hasStepsToHide]);

  if (loading) {
    return <p>Preparing the form...</p>;
  }

  if (error && !loading) {
    alert('Error: ' + error);
  }

  return children;
};

const mapStateToPropsHideSteps = (state) => {
  const bizId = selector(state, 'business_id');
  const acctId = bizId ? bizId : state.user.accountId;
  return {
    routes: state.brand?.data?.routes,
    finalInAppValues: state.form.application?.values,
    routesToHide: state.routesToHide,
    config: state.config,
    user: state.user,
    vendor_name: state.brand?.data?.vendor_name,
    accountId: acctId,
    contact1Id: selector(state, 'owner_1_id'),
    loanId: selector(state, 'loan_id'),
  };
};

const HideSteps = connect(mapStateToPropsHideSteps)(HideStepsIfAny);

const ParamsFromUrlWrapperInner = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    // This might need a better way to handle this.
    // So far we have no partner integration that uses this in Apply 1.5
    // The value is later sent to backend using createInTransaction call.
    // Current implementation might collect query string params from multiple pages. Be aware each call is then just spread into the state that is in the Redux store already ({ ...state, ...action.partnerCustomFields })
    dispatch({ type: 'SET_PARAMS_DATA', partnerCustomFields: queryString.parse(location.search) });
  }, [location.search]);

  return (
    <PrefillStepsWrapper config={props.config}>
      <LoadStepsToHideFromGetParamsWrapper formValues={props.formValues}>
        <HideSteps callApi={props.callApi} dataLayerPush={props.dataLayerPush}>
          {props.children}
        </HideSteps>
      </LoadStepsToHideFromGetParamsWrapper>
    </PrefillStepsWrapper>
  );
};

const selector = formValueSelector('application');

const mapStateToProps = (state) => ({
  config: state.config,
  formValues: state.form.application?.values,
});
export const ParamsFromUrlWrapper = connect(mapStateToProps)(ParamsFromUrlWrapperInner);
