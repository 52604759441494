import React from 'react';
import { contactEmail } from '../../../utils/platformBasedInfo.js';
import { backendUrl } from '../../../utils/platformBasedInfo.js';
import { Stack } from '@components/Stack/Stack';
import { ELEMENTS_GAP } from '@utils/spacing';

export const Intro = (props) => {
  const loanText = props.loanType === 'EQ' ? 'equipment financing' : 'working capital';

  return (
    <React.Fragment>
      <p>Your {loanText} application is complete!</p>
      <p>We will begin working on your request shortly, and you should expect to hear from us within 1-2 days.</p>
    </React.Fragment>
  );
};

export const Outro = (props) => {
  const { loanType, tib, temp } = props;
  const { haveStatements } = temp;

  const loanTypeSafe = Array.isArray(loanType)
    ? loanType.filter((lt) => lt !== 'UNKNOWN').length > 0
      ? loanType
          .filter((lt) => lt !== 'UNKNOWN')[0]
          .toString()
          .toUpperCase()
      : 'UNKNOWN'
    : loanType?.toString().toUpperCase() ?? 'UNKNOWN';

  if (props.temp.signingResult === 'signing_complete') {
    return null;
  }

  let documents = [];

  if (loanTypeSafe === 'WC') {
    if (!haveStatements) {
      documents.push('Last 4 months business bank statements (all pages)');
    }
  }
  if (loanTypeSafe === 'EQ') {
    if (!haveStatements) {
      documents.push('Last 4 months bank statements');
    }
    if (tib < 2) {
      documents.push({
        link: `${backendUrl}/download/pfs`,
        text: 'Personal Financial Statement.pdf',
      });
      documents.push({
        link: `${backendUrl}/download/suq`,
        text: 'Startup Questionnaire.pdf',
      });
    }
    documents.push('Quote on the equipment you would like to purchase');
  }

  if (documents.length > 0) {
    return (
      <Stack gap={ELEMENTS_GAP}>
        <p data-testid="email-text-message">
          In the meantime, please email the following documents to{' '}
          <a
            // global properties are tag with !important, make it impossible to make a cleaner solution without the entire project
            ref={(el) => el && el.style.setProperty('color', 'var(--default-primary-color)', 'important')}
            href={`mailto:${contactEmail}`}
          >
            {contactEmail}
          </a>
          :
        </p>
        <ul className="left-list-inverse">
          {documents.map((item, idx) => {
            if (typeof item === 'object') {
              return (
                <li key={idx} className="list-item list-[square]">
                  <a href={item.link} style={{ color: 'black !important' }}>
                    {item.text}
                  </a>
                </li>
              );
            } else {
              return (
                <li key={idx} className="list-item list-[square]">
                  {item}
                </li>
              );
            }
          })}
        </ul>
      </Stack>
    );
  } else {
    return (
      <div className="questions">
        <Stack gap={ELEMENTS_GAP}>
          <p>
            If you have questions in the meantime, please email us at{' '}
            <a className="mailto_hello" href={`mailto:${contactEmail}`}>
              {contactEmail}
            </a>
            .
          </p>
          <li>Quote on the equipment you would like to purchase.</li>
        </Stack>
      </div>
    );
  }
};
