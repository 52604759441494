import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider, connect } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { BrowserRouter, useHistory } from 'react-router-dom';
import { store, persistor } from '@store/store';
import { auth0ClientId, auth0Domain, isSyntheticTest } from '@utils/platformBasedInfo';

import ErrorBoundary from './components/_shared/Error/Error';
import TagManager from 'react-gtm-module';
import { App, auth0AuthroizationParams } from './App';
import { fetchBrand } from './utils/fetchBrand';
import { BrandLoadingScreen } from './components/Brand/BrandLoadingScreen';
import { setCssThemeVariables } from './utils/setCssThemeVariables';
import packageJson from '../../package.json';
import { detectEnvironment } from './detectEnvironment';
import { ApplyWizardProvider } from './context/ApplyWizardContext';
import { Auth0Provider } from '@auth0/auth0-react';

if (!isSyntheticTest) {
  datadogRum.init({
    version: packageJson?.version ?? 'unknown',
    applicationId: '197ef787-da5f-470b-8151-df8203250d1c',
    clientToken: 'pub12b2d7c792ba4ae64360c3dadb6cbe9f',
    site: 'datadoghq.com',
    service: 'tento-apply',
    env: detectEnvironment(),
    // Specify a version number to identify the deployed version of your application in Datadog
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
    beforeSend: (event) => {
      if (event.type === 'error') {
        const errorMessage = event.error?.message || '';
        if (
          errorMessage.includes('Object Not Found Matching') ||
          errorMessage.includes('XHR error POST https://px.ads.linkedin.com') ||
          errorMessage.includes('XHR error POST https://events.launchdarkly.com/events/bulk') ||
          errorMessage.includes('network error (Error)') || // Launch Darkly network error on end user devices https://support.launchdarkly.com/hc/en-us/articles/12998125691419-Error-LaunchDarklyFlagFetchError-network-error
          errorMessage.includes('[LaunchDarkly] network error') ||
          errorMessage.includes('Fetch error POST https://api.segment.io') ||
          errorMessage.includes('Error sending segment')
        ) {
          // Return false to prevent this event from being sent to Datadog
          return false;
        }
      }
      // Return true to allow the event to be sent
      return true;
    },
  });

  datadogLogs.init({
    version: packageJson?.version ?? 'unknown',
    ...({ applicationId: '197ef787-da5f-470b-8151-df8203250d1c' } as any), // THIS IS NOT DETECTED BY TYPESCRIPT TYPES BUT WAS HERE BEFORE. HAD NO GUTS TO REMOVE.
    clientToken: 'pub12b2d7c792ba4ae64360c3dadb6cbe9f',
    site: 'datadoghq.com',
    service: 'tento-apply',
    env: detectEnvironment(),
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    beforeSend: (logEvent) => {
      const message = logEvent.message || '';

      if (
        message.includes('Object Not Found Matching') ||
        message.includes('XHR error POST https://px.ads.linkedin.com') ||
        message.includes('XHR error POST https://events.launchdarkly.com/events/bulk') ||
        message.includes('network error (Error)') || // Launch Darkly network error on end user devices https://support.launchdarkly.com/hc/en-us/articles/12998125691419-Error-LaunchDarklyFlagFetchError-network-error
        message.includes('[LaunchDarkly] network error') ||
        message.includes('Fetch error POST https://api.segment.io') ||
        message.includes('Error sending segment')
      ) {
        // Return false to prevent this log event from being sent to Datadog
        return false;
      }

      // Return true to allow the log event to be sent
      return true;
    },
  });

  // TODO: Set these to the correct values
  datadogRum.setGlobalContextProperty('partner-name', 'none');
  datadogRum.setGlobalContextProperty('partner-id', 'none');

  datadogRum.startSessionReplayRecording();

  TagManager.initialize({
    gtmId: process.env.GOOGLE_TAG_MANAGER_ID || 'missing GOOGLE_TAG_MANAGER_ID',
  });
}

const InnerApp = () => {
  const history = useHistory();

  return (
    <Auth0Provider
      onRedirectCallback={(appState) => {
        if (appState && appState.returnTo) {
          history.push({
            pathname: appState.returnTo,
            state: appState,
          });
        }
      }}
      skipRedirectCallback={false}
      domain={auth0Domain}
      clientId={auth0ClientId}
      authorizationParams={auth0AuthroizationParams}
    >
      <ApplyWizardProvider>
        <App />
      </ApplyWizardProvider>
    </Auth0Provider>
  );
};

(async () => {
  const domNode = document.getElementById('root');
  if (domNode) {
    const root = createRoot(domNode);
    const initialReduxState = {};

    const RootInner = (props: any) => {
      useEffect(() => {
        fetchBrand();
      }, []);

      useEffect(() => {
        if (props.theme) {
          setCssThemeVariables(props.theme);
          if (!process.env.STORYBOOK_TEST) {
            const linkElement = document.getElementById('faviconLink') as HTMLLinkElement | null;
            if (linkElement) linkElement.href = props?.media?.favicon_url || '';
          }
        }
      }, [props.theme]);

      return props.vendor_id ? (
        <BrowserRouter basename={props.route_prefix}>
          <ErrorBoundary>
            <InnerApp />
          </ErrorBoundary>
        </BrowserRouter>
      ) : (
        <BrandLoadingScreen failed={!!store.getState().brand?.error} />
      );
    };

    const mapStateToProps = (state: any) => {
      return {
        theme: state.brand?.data?.theme,
        vendor_id: state.brand?.data?.vendor_id,
        route_prefix: state.brand?.data?.route_prefix,
        images: state.brand?.data?.media,
      };
    };
    const ConnectedRootInner = connect(mapStateToProps)(RootInner);

    const Root = () => (
      <Provider store={store} serverState={initialReduxState}>
        <PersistGate loading={null} persistor={persistor}>
          <ConnectedRootInner />
        </PersistGate>
      </Provider>
    );

    root.render(<Root />);
  } else {
    console.error('No root element');
  }
})();
