import React from 'react';
import { currency, currencyDecimal } from '_fields/normalizers';
import { estPaymentByApr } from '@utils/paymentCalculations';
import './Payment.scss';

/* used for app/amount when loan type is EQ */
export const PaymentTerm = ({ loanType, term, rate, amount }) => {
  if (loanType === 'EQ') {
    const paymentAmount = estPaymentByApr(amount, term, rate);

    return <div className="eq-monthlyPayment">{currency(paymentAmount)}/month</div>;
  }

  return null;
};

/* used in the instant approval flow for agreement/terms AND confirm modal */
export const PaymentSelected = (props) => {
  const paymentFrequency = props.offer.monthly ? 'Monthly' : 'Weekly';
  const paymentInterest = <PaymentInterest {...props} />;

  return (
    <span>
      {paymentFrequency} payments of {currencyDecimal(props.payment)}*
    </span>
    // <table className="paymentDisplay">
    //   <tbody>
    //     <tr className="payment">
    //       <td className="paymentText">Total {paymentFrequency} payment</td>
    //       <td className="paymentAmount">{currencyDecimal(props.payment)}</td>
    //     </tr>
    //     {paymentInterest}
    //   </tbody>
    // </table>
  );
};

const PaymentInterest = (props) => {
  const loanType = props.offer.loanType;
  const loanTypeSafe = Array.isArray(loanType)
    ? loanType.filter((lt) => lt !== 'UNKNOWN').length > 0
      ? loanType
          .filter((lt) => lt !== 'UNKNOWN')[0]
          .toString()
          .toUpperCase()
      : 'UNKNOWN'
    : loanType?.toString().toUpperCase() ?? 'UNKNOWN';
  if (loanTypeSafe === 'WC') {
    return (
      <tr className="dailyInterest">
        <td className="dailyText">Interest per day</td>
        <td className="interestAmount">{currencyDecimal(props.interest)}</td>
      </tr>
    );
  }

  return null;
};
