import React, { useEffect, ComponentType } from 'react';
import { isSyntheticTest } from '../../../utils/platformBasedInfo';
import { updateLoaneeDeal } from '../../../api/updateLoaneeDeal';
import { DataError } from '../../../api/DataError';
import Alert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';
import { currencyToDecimal } from '../../../utils/currencyToDecimal';
import { addOwnersToPost } from './addOwnersToPost';
import { ContactFormattedResponse, EntireDealFormattedResponse } from '../../../types';
import {
  ApplyWizardStep,
  STEP_BCM_BUSINESS_MORE_DETAILS,
  STEP_BCM_SUMMARY,
  STEP_BUSINESS_FURTHER_DETAILS,
  STEP_BUSINESS_MORE_DETAILS,
  STEP_BUSINESS_OWNERS,
  STEP_SUMMARY,
  STEP_TYPE_OF_FUNDING,
  STEP_WHEN_FUNDING_NEEDED,
} from '../../ApplyWizard/ApplyWizardSteps';
import { connect, useDispatch } from 'react-redux';
import { change, formValueSelector } from 'redux-form';
import { RootState } from '@store/reducers.js';
import { MAX_SANE_NUMBER_OF_OWNERS } from '@components/Apply/OwnersMoreDetailsAboutYou/getNumberOfOwners';
import { Dispatch } from 'redux';
import { useApplyWizardContext } from '../../../context/ApplyWizardContext';

const appSelector = formValueSelector('application');

const enrichOwnersWithIds = (state: RootState) => (dispatch: Dispatch, contacts: ContactFormattedResponse[]) => {
  const maxOwners = MAX_SANE_NUMBER_OF_OWNERS;
  for (let i = 1; i <= maxOwners; i++) {
    if (
      appSelector(state, `owner_${i}_id`) ||
      appSelector(state, `owner_${i}_street`) ||
      appSelector(state, `owner_${i}_suite`) ||
      appSelector(state, `owner_${i}_city`) ||
      appSelector(state, `owner_${i}_state`) ||
      appSelector(state, `owner_${i}_zip`) ||
      appSelector(state, `owner_${i}_first`) ||
      appSelector(state, `owner_${i}_last`) ||
      appSelector(state, `owner_${i}_ssn`) ||
      appSelector(state, `owner_${i}_mobile`) ||
      appSelector(state, `owner_${i}_dob_day`) ||
      appSelector(state, `owner_${i}_dob_month`) ||
      appSelector(state, `owner_${i}_dob_year`) ||
      appSelector(state, `owner_${i}_title`) ||
      appSelector(state, `owner_${i}_ownership`) ||
      appSelector(state, `owner_${i}_email`)
    ) {
      // Owner [i] exists, so we can enrich it with ID if it exists in backend response.
      // Matching by email.
      const owner = contacts.find((c) => c.email === appSelector(state, `owner_${i}_email`));
      if (owner && owner.id) {
        dispatch(change('application', `owner_${i}_id`, owner.id));
      }
    }
  }
};

interface WithOnSubmitProps {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}

type ExpectedProps = {
  partnerCustomFields?: any;
  loan_id: string;
  type_of_statements: 'email' | 'upload' | 'plaid';
  enrichOwners: ReturnType<typeof enrichOwnersWithIds>;
};

const withOnSubmit = <P extends object>(WrappedComponent: ComponentType<P>, wizardStep: ApplyWizardStep) => {
  const HOC: React.FC<Omit<P, keyof WithOnSubmitProps> & ExpectedProps> = (props) => {
    const [{ data: dealUpdateResponse, loading: dealUpdateLoading, error: dealUpdateError }, updateOpportunity] =
      updateLoaneeDeal();
    const dispatch = useDispatch();
    const { nextRoute, dataLayerPush, applicationFlow } = useApplyWizardContext();

    useEffect(() => {
      if (dealUpdateResponse && nextRoute) {
        nextRoute();
      }
    }, [dealUpdateResponse]);

    useEffect(() => {
      if (process.env.STORYBOOK_TEST) return;
    }, []);

    const onSubmitFunction = async (form: any) => {
      let post: EntireDealFormattedResponse = {};
      console.log('form submitted:', form);

      if (
        [
          STEP_BUSINESS_MORE_DETAILS,
          STEP_BCM_BUSINESS_MORE_DETAILS,
          STEP_BUSINESS_FURTHER_DETAILS,
          STEP_BUSINESS_OWNERS,
          STEP_BCM_SUMMARY,
          STEP_SUMMARY,
        ].includes(wizardStep)
      ) {
        if (form[`business_accountId`]) {
          if (!post.business) {
            post.business = {};
          }
          post.business.id = form[`business_accountId`];
        }
        if (form[`business_account_id`]) {
          if (!post.business) {
            post.business = {};
          }
          post.business.id = form[`business_account_id`];
        }
      }

      if (wizardStep === STEP_WHEN_FUNDING_NEEDED) {
        if (form[`when_funding_needed`]) {
          if (!post.loanRequest) {
            post.loanRequest = {};
          }
          post.loanRequest.whenFundingNeeded = form[`when_funding_needed`];
        }
      }
      if (wizardStep === STEP_TYPE_OF_FUNDING) {
        if (form[`loan_type`]) {
          if (!post.loanRequest) {
            post.loanRequest = {};
          }
          if (!post.loanRequest.metadata) {
            post.loanRequest.metadata = {};
          }
          const loanType = form.loan_type;
          const loanTypeSafe = Array.isArray(loanType)
            ? loanType.filter((lt) => lt !== 'UNKNOWN').length > 0
              ? loanType
                  .filter((lt) => lt !== 'UNKNOWN')[0]
                  .toString()
                  .toUpperCase()
              : 'UNKNOWN'
            : loanType?.toString().toUpperCase() ?? 'UNKNOWN';
          post.loanRequest.type = loanTypeSafe;
          post.loanRequest.metadata = {
            ...post.loanRequest.metadata,
            loanTypes:
              loanType && Array.isArray(loanType)
                ? loanType.filter((lt) => lt !== 'UNKNOWN').length > 0
                  ? loanType.filter((lt) => lt !== 'UNKNOWN').map((lt) => lt.toString().toUpperCase())
                  : ['UNKNOWN']
                : loanType
                ? [loanType.toString().toUpperCase()]
                : ['UNKNOWN'],
          };
        }
        if (form[`equipment_description`]) {
          if (!post.loanRequest) {
            post.loanRequest = {};
          }
          post.loanRequest.equipmentDesc = form.equipment_description;
        }
      }

      if (wizardStep === STEP_BUSINESS_MORE_DETAILS) {
        if (form[`home_based`]) {
          if (!post.business) {
            post.business = {};
          }
          post.business.home_based = form[`home_based`] === 'true';
        }
        if (form[`business_years`]) {
          if (!post.business) {
            post.business = {};
          }
          if (!post.loanRequest) {
            post.loanRequest = {};
          }
          post.loanRequest.id = props.loan_id;
          post.business.businessYears = form[`business_years`];
        }

        if (form[`business_website`]) {
          if (!post.business) {
            post.business = {};
          }
          post.business.website = form[`business_website`];
          (async () => {
            if (!isSyntheticTest) {
              analytics.track(
                applicationFlow === 'bcm' ? 'Apply BCM Form - Business Website' : 'Apply Form - Business Website',
                {
                  website: post?.business?.website,
                }
              );
            }
          })();
        }
        if (form[`business_naics`]) {
          if (!post.business) {
            post.business = {};
          }
          post.business.naicsCode = form[`business_naics`];
          (async () => {
            if (!isSyntheticTest) {
              analytics.track(
                applicationFlow === 'bcm' ? 'Apply BCM Form - Business Naics' : 'Apply Form - Business Naics',
                {
                  naics: post?.business?.naicsCode,
                }
              );
            }
          })();
        }

        if (form[`business_structure`]) {
          if (!post.business) {
            post.business = {};
          }
          post.business.entityType = form[`business_structure`];
          (async () => {
            if (!isSyntheticTest) {
              analytics.track(
                applicationFlow === 'bcm' ? 'Apply BCM Form - Business Structure' : 'Apply Form - Business Structure',
                {
                  structure: post?.business?.entityType,
                }
              );
            }
          })();
        }

        if (form[`business_years`] && dataLayerPush) {
          if (!isSyntheticTest) {
            dataLayerPush('time-in-business', {
              account_id: post.business?.id,
              time_in_business: form[`business_years`],
            });
          }
        }

        if (
          form.business_street ||
          form.business_suite ||
          form.business_city ||
          form.business_state ||
          form.business_zip
        ) {
          if (!post.business) {
            post.business = {};
          }
          post.business.address = {
            address1: form.business_street || null,
            address2: form.business_suite || null,
            city: form.business_city || null,
            state: form.business_state || null,
            postalCode: form.business_zip || null,
          };

          if (form[`home_based`]) {
            dispatch(change('application', 'owner_1_street', form.business_street || ''));
            dispatch(change('application', 'owner_1_city', form.business_city || ''));
            dispatch(change('application', 'owner_1_state', form.business_state || ''));
            dispatch(change('application', 'owner_1_zip', form.business_zip || ''));
            dispatch(change('application', 'owner_1_suite', form.business_suite || ''));
          }

          if (props.partnerCustomFields) {
            if (!post.source) {
              post.source = {};
            }
            post.source.customFields = props.partnerCustomFields;
          }
        }

        // This 2 fields were originally always sent on this step (previously there were multiple steps which are now merged into one)
        if (form[`business_phone`]) {
          // Business phone number step is removed from the form as per:
          if (!post.business) {
            post.business = {};
          }
          post.business.phone = form[`business_phone`];
          (async () => {
            if (!isSyntheticTest) {
              analytics.track(
                applicationFlow === 'bcm' ? 'Apply BCM Form - Business Phone' : 'Apply Form - Business Phone',
                {
                  phone: post?.business?.phone,
                }
              );
            }
          })();
        }
        if (form[`business_type`]) {
          if (!post.business) {
            post.business = {};
          }
          post.business.entityType = form[`business_type`];
        }
      }
      if (wizardStep === STEP_BCM_BUSINESS_MORE_DETAILS) {
        (async () => {
          if (!isSyntheticTest) {
            analytics.track(
              applicationFlow === 'bcm'
                ? 'Apply BCM Form - Business More Details'
                : 'Apply Form - Business More Details',
              {
                ...form,
              }
            );
          }
        })();
        if (
          form.business_street ||
          form.business_suite ||
          form.business_city ||
          form.business_state ||
          form.business_zip
        ) {
          if (!post.business) {
            post.business = {};
          }
          post.business.address = {
            address1: form.business_street || null,
            address2: form.business_suite || null,
            city: form.business_city || null,
            state: form.business_state || null,
            postalCode: form.business_zip || null,
          };

          (async () => {
            if (!isSyntheticTest) {
              analytics.track(
                applicationFlow === 'bcm' ? 'Apply BCM Form - Business Address' : 'Apply Form - Business Address',
                {
                  ...post?.business?.address,
                }
              );
            }
          })();
          if (props.partnerCustomFields) {
            if (!post.source) {
              post.source = {};
            }
            post.source.customFields = props.partnerCustomFields;
          }
        }

        if (form[`business_ein`]) {
          if (!post.business) {
            post.business = {};
          }
          post.business.taxIdNumber = form[`business_ein`];
          (async () => {
            if (!isSyntheticTest) {
              analytics.track(
                applicationFlow === 'bcm' ? 'Apply BCM Form - Business Ein' : 'Apply Form - Business Ein',
                {
                  ein: post?.business?.taxIdNumber,
                }
              );
            }
          })();
        }
        if (form[`business_dba`]) {
          if (!post.business) {
            post.business = {};
          }
          post.business.doingBusinessAs = form[`business_dba`];
        }
        if (form[`owner_1_id`] && form[`owner_1_title`]) {
          if (!post.business) {
            post.business = {};
          }
          post.business.contacts = [
            {
              id: form[`owner_1_id`],
              title: form[`owner_1_title`],
            },
          ];
        }

        if (form[`business_phone`] || form['owner_1_mobile']) {
          // Business phone number step is removed from the form but Contact phone is collected on Step Info and assigned as owner_1_mobile
          if (!post.business) {
            post.business = {};
          }
          post.business.phone = form[`business_phone`] ? form[`business_phone`] : form['owner_1_mobile']; // Treat Info step mobile as business phone
        }
      }

      if (wizardStep === STEP_BUSINESS_FURTHER_DETAILS) {
        if (form[`business_revenue`]) {
          if (!post.business) {
            post.business = {};
          }
          post.business.annualRevenue = currencyToDecimal(form[`business_revenue`]);
          (async () => {
            if (!isSyntheticTest) {
              analytics.track(
                applicationFlow === 'bcm'
                  ? 'Apply BCM Form - Business Reported Revenue'
                  : 'Apply Form - Business Reported Revenue',
                {
                  revenue: post?.business?.annualRevenue,
                }
              );
            }
          })();
          (async () => {
            if (!isSyntheticTest) {
              analytics.track(
                applicationFlow === 'bcm' ? 'Apply BCM Form - Business Revenue' : 'Apply Form - Business Revenue',
                {
                  revenue: post?.business?.annualRevenue,
                }
              );
            }
          })();
        }
        if (form[`business_ein`]) {
          if (!post.business) {
            post.business = {};
          }
          post.business.taxIdNumber = form[`business_ein`];
        }

        if (form[`business_revenue`] && dataLayerPush) {
          if (!isSyntheticTest) {
            dataLayerPush('user-reported-revenue', {
              account_id: post.business?.id,
              time_in_business: form[`business_revenue`],
            });
          }
        }
      }

      if (wizardStep === STEP_BUSINESS_OWNERS) {
        post = addOwnersToPost({ post, form });
      }

      if (wizardStep === STEP_BCM_SUMMARY) {
        (async () => {
          if (!isSyntheticTest) {
            analytics.track('Apply BCM Form - Done', {
              ...form,
            });
          }
        })();
        if (!post.business) {
          post.business = {};
        }
        post.business.bcmStatus = 'approved';
        post.business.bcmSubscriptionType = 'freetrial';
        post.business.bcmSubscriptionSource = 'bcm-flow-onboarding';
        post.business.bcmSubscriptionValidUntil = 'indefinitely';
        if (form[`business_ein`]) {
          if (!post.business) {
            post.business = {};
          }
          post.business.taxIdNumber = form[`business_ein`];
        }
        if (form[`business_dba`]) {
          if (!post.business) {
            post.business = {};
          }
          post.business.doingBusinessAs = form[`business_dba`];
        }
        if (form[`business_name`]) {
          if (!post.business) {
            post.business = {};
          }
          post.business.name = form[`business_name`];
        }
      }

      if (wizardStep === STEP_SUMMARY) {
        (async () => {
          if (!isSyntheticTest) {
            analytics.track('Apply Form - Submit Application', {});
          }
        })();
        if (!post.business) {
          post.business = {};
        }
        post.business.bcmStatus = 'approved';
        post.business.bcmSubscriptionType = 'freetrial';
        post.business.bcmSubscriptionSource = 'apply-loan-submitted';
        post.business.bcmSubscriptionValidUntil = 'indefinitely';
        if (form[`loan_amount`]) {
          if (!post.loanRequest) {
            post.loanRequest = {};
          }
          post.loanRequest.amount = currencyToDecimal(form[`loan_amount`]);
        }
        if (form[`loan_terms`]) {
          if (!post.loanRequest) {
            post.loanRequest = {};
          }
          post.loanRequest.termUnit = 'month';
          post.loanRequest.termLength = form[`loan_terms`];
        }
        if (form[`business_revenue`]) {
          if (!post.business) {
            post.business = {};
          }
          post.business.annualRevenue = currencyToDecimal(form[`business_revenue`]);
        }
        if (form[`business_ein`]) {
          if (!post.business) {
            post.business = {};
          }
          post.business.taxIdNumber = form[`business_ein`];
        }
        if (form[`business_years`]) {
          if (!post.business) {
            post.business = {};
          }
          if (!post.loanRequest) {
            post.loanRequest = {};
          }
          post.loanRequest.id = props.loan_id;
          post.business.businessYears = form[`business_years`];
          (async () => {
            if (!isSyntheticTest) {
              analytics.track('Apply Form - Business Years', {
                years: post?.business?.businessYears,
              });
            }
          })();
        }
        if (form[`business_name`]) {
          if (!post.business) {
            post.business = {};
          }
          post.business.name = form[`business_name`];
        }
        if (!post.loanRequest) {
          post.loanRequest = {};
        }
        if (props.type_of_statements === 'plaid' || props.type_of_statements === 'upload') {
          post.loanRequest.stage = 'ApplySubmitted';
        } else {
          post.loanRequest.stage = 'AwaitingStatements';
        }
      }

      const response = await updateOpportunity({ data: post });
      const dealUpdateResponse = response.data;

      if (wizardStep === STEP_BUSINESS_OWNERS) {
        if (dealUpdateResponse?.contactIds?.length > 0) {
          props.enrichOwners(dispatch, dealUpdateResponse.contactIds);
        }
      }
    };

    return (
      <>
        <WrappedComponent {...(props as P)} onSubmit={onSubmitFunction} saving={dealUpdateLoading} />
        {dealUpdateError && (
          <Alert severity="error">
            <AlertTitle>Failed to save</AlertTitle>
            <DataError msg="Unfortunately, we couldn't save your update." error={dealUpdateError} />
          </Alert>
        )}
      </>
    );
  };

  HOC.displayName = `withOnSubmit(${getDisplayName(WrappedComponent)})`;

  const mapStateToProps = (state: RootState) => {
    return {
      enrichOwners: enrichOwnersWithIds(state),
      partnerCustomFields: state.partnerCustomFields,
      loan_id: appSelector(state, 'loan_id'),
      type_of_statements: appSelector(state, 'type_of_statements'),
    };
  };

  return connect(mapStateToProps)(HOC as any);
};

const getDisplayName = (WrappedComponent: ComponentType<any>): string => {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
};

export default withOnSubmit;
