import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import auth0 from 'auth0-js';
import { Stack, Text, Button, useHasFullLoaderTimeElapsed, useCountdown, cn, useIsInIframe } from 'shared-components';
import { FormPageTopPart } from '@components/_shared/FormPageTopPart/FormPageTopPart';
import { reduxForm, Field } from 'redux-form';
import { newThemeInput } from '_fields/inputs';
import { reduxFormZodValidate } from '@utils/reduxFormZodValidate';
import { formValueSelector } from 'redux-form';
import { z } from 'zod';
import { Form } from '@components/shadcn/components/ui/form';
import { useForm } from 'react-hook-form';
import { RootState } from '@store/reducers';
import { connect } from 'react-redux';
import ContinueButton from '@components/_shared/Continue/Button';
import { useHistory } from 'react-router-dom';
import { auth0ClientId, auth0Domain } from '@utils/platformBasedInfo';

interface CustomLoginProps {
  ownerEmail: string;
  code: string;
}

const loginSchema = z.object({
  owner_1_email: z.string().email('Please enter a valid email'),
  code: z.string().optional(),
});

const CustomLoginInner: React.FC<CustomLoginProps> = ({ ownerEmail, code }) => {
  const [error, setError] = useState('');
  const [codeSent, setCodeSent] = useState(!!ownerEmail);
  const [isLoading, setIsLoading] = useState(false);
  const form = useForm();
  const { timeLeft, setTimeLeft } = useCountdown(60);
  const history = useHistory();
  const isOpenedInIframe = useIsInIframe();

  const auth0Client = new auth0.WebAuth({
    domain: auth0Domain,
    clientID: auth0ClientId,
    responseType: 'token id_token',
    scope: 'openid profile email',
    redirectUri: `${window.location.origin}/app/verify/login`,
  });

  const sendCode = async () => {
    setError('');
    try {
      auth0Client.passwordlessStart(
        {
          connection: 'email',
          send: 'code',
          email: ownerEmail,
        },
        (err) => {
          if (err) {
            setError(err.description || 'Failed to send code');
          } else {
            setTimeLeft(60);
            setCodeSent(true);
          }
        }
      );
    } catch (error: any) {
      setError(error.message || 'An error occurred');
    }
  };

  const verifyCode = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    try {
      if (isOpenedInIframe) {
        window.parent.postMessage(JSON.stringify({ type: 'iframe-bg-add' }), '*');
      }
      const res = await auth0Client.passwordlessLogin(
        {
          connection: 'email',
          email: ownerEmail,
          verificationCode: code,
        },
        (err, result) => {
          if (err) {
            setIsLoading(false);
            setError(err.description || 'Invalid code');
          } else {
            history.push('/app/verify/login');
          }
        }
      );
    } catch (error: any) {
      setIsLoading(false);
      setError(error.message || 'An error occurred');
    }
  };

  useEffect(() => {
    if (ownerEmail) {
      sendCode();
    }
  }, []);

  return (
    <div>
      <Stack gapVariant="head-section-to-content">
        <FormPageTopPart
          headingText={codeSent ? 'Verify Your Identity' : 'Welcome'}
          subHeadingText={
            codeSent ? `We've sent an email with your code to ${ownerEmail}` : 'Enter your email to continue...'
          }
        />
        <Form {...form}>
          <form
            onSubmit={
              codeSent
                ? verifyCode
                : (e) => {
                    e.preventDefault();
                    sendCode();
                  }
            }
            className="slideWidth"
          >
            <Stack gapVariant={'content-to-submit-button'}>
              <Stack gapVariant={'input-to-input'} justifyItems="center" templateColumns="1fr" fullWidth>
                <Field
                  name="owner_1_email"
                  component={newThemeInput}
                  type="email"
                  label="Email*"
                  placeholder="Enter your email"
                  data-testid="owner_1_email"
                  disabled={codeSent}
                />

                {codeSent && (
                  <Field
                    name="code"
                    component={newThemeInput}
                    type="text"
                    label="Verification Code*"
                    placeholder="Enter the code sent to your email"
                    data-testid="code"
                  />
                )}

                {error && (
                  <Text variant="error" className="mt-2">
                    {error}
                  </Text>
                )}
                {codeSent && (
                  <div className="text-center">
                    <Button
                      variant="text"
                      onClick={() => {
                        setCodeSent(false);
                        setTimeLeft(60);
                      }}
                      disabled={timeLeft > 0}
                      className={cn('hover:text-charcoal inline underline text-[14px] h-[20px] p-0', {
                        ['text-charcoal']: timeLeft === 0,
                        ['text-charcoalHover']: timeLeft > 0,
                      })}
                    >
                      Resend Code or Change Email {timeLeft > 0 && `(active in ${timeLeft})`}
                    </Button>
                  </div>
                )}
              </Stack>
              <Stack alignItems="center" justifyContent="center">
                <ContinueButton
                  invalid={false}
                  btnText={isLoading ? 'Processing...' : codeSent ? 'Verify Code' : 'Send Code'}
                  disabled={isLoading}
                  saving={isLoading}
                  showLock
                  data-testid="info_continue"
                />
              </Stack>
            </Stack>
          </form>
        </Form>
      </Stack>
    </div>
  );
};

export const CustomLoginForm = reduxForm<CustomLoginProps, CustomLoginProps>({
  form: 'application',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: reduxFormZodValidate(loginSchema),
})(CustomLoginInner);

const appSelector = formValueSelector('application');

const mapStateToProps = (state: RootState) => {
  return {
    ownerEmail: appSelector(state, 'owner_1_email'),
    code: appSelector(state, 'code'),
  };
};

export const CustomLogin = connect(mapStateToProps)(CustomLoginForm);
