import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { formValueSelector, getFormValues } from 'redux-form';
import convertKeysToCamelCase from '@utils/convertKeysToCamelCase';
import Info from './Info';
import { changePriceToNumber } from '../../../utils/changePriceToNumber';
import { isSyntheticTest } from '../../../utils/platformBasedInfo';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min.js';
import { setUser } from '@store/actions';
import { mockAuth0User } from '../../../../../tests/mockAuth0User.jsx';
import { updateLoaneeDeal } from '../../../api/updateLoaneeDeal';
import { useApplyWizardContext } from '../../../context/ApplyWizardContext';
import { useIsInIframe } from 'shared-components';
import { ApplyWizardSteps, STEP_INTEGRATED_LOGIN } from '@components/ApplyWizard/ApplyWizardSteps';

const InfoContainer = ({
  user,
  dispatch,
  finalWizardRoutes,
  loanType,
  type,
  allValues,
  formValues,
  routes,
  contact_id,
  ...other
}) => {
  const {
    nextRoute,
    dataLayerPush,
    showProcessingModal,
    saving,
    callApi,
    setSaving,
    steps,
    applicationFlow,
    changeStepTo,
  } = useApplyWizardContext();
  const isOpenedInIframe = useIsInIframe();
  const auth0 = useAuth0();
  const history = useHistory();
  const [{ data: dealUpdateResponse, loading: dealUpdateLoading, error: dealUpdateError }, updateOpportunity] =
    updateLoaneeDeal();

  useEffect(() => {
    if (process.env.STORYBOOK_TEST) return;
  }, []);

  const saveInfo = async () => {
    // if (!process.env.STORYBOOK_TEST) {
    //   analytics.identify({
    //     firstName: formValues.owner_1_first,
    //     lastName: formValues.owner_1_last,
    //     email: formValues.owner_1_email,
    //     company: { name: formValues.business_name },
    //     phone: formValues.owner_1_mobile,
    //   });
    // }

    let post = {
      business: formValues.business_name,
      first: formValues.owner_1_first,
      last: formValues.owner_1_last,
      mobile: formValues.owner_1_mobile,
      email: formValues.owner_1_email,
      amount: changePriceToNumber(formValues.loan_amount),
      terms: formValues.loan_terms,
      payment: formValues.loan_payment,
      consent: formValues.consent,
      equipment_description: formValues.equipment_description,
      ga_info: formValues.ga_info,
      loan_type: loanType,
      type: user.type,
    };

    // if user is logged in we need to pass some extra info in case deal creation is required
    // this can occur when user has completed deal and is logged in, but then starts another
    if (user.loggedIn) {
      const extraPost = {
        contact_type: 'Contact',
        loggedIn: true,
      };

      post = Object.assign({}, post, extraPost);
    }

    // if (!process.env.STORYBOOK_TEST && !isSyntheticTest) {
    // Removed as we track now based on ID not mobile (Vic request from: https://www.loom.com/share/7cb4e6fa7c0f4b36a71a7c672489f172)
    //   analytics.identify(formValues.owner_1_mobile, {
    //     firstName: formValues.owner_1_first,
    //     lastName: formValues.owner_1_last,
    //     email: formValues.owner_1_email,
    //     company: { name: formValues.business_name },
    //     phone: formValues.owner_1_mobile,
    //   });
    // }

    if (!process.env.STORYBOOK_TEST && !isSyntheticTest) {
      const segmentPost = convertKeysToCamelCase(post);
      if (applicationFlow === 'bcm') {
        analytics.track('Apply BCM Form - Info', segmentPost);
      } else {
        analytics.track('Apply Form - Info', segmentPost);
      }
    }

    if (!auth0.isAuthenticated) {
      if (process.env.STORYBOOK_TEST) {
        dispatch(
          setUser({
            user: {
              loggedIn: true,
              accountId: 'pubAccId',
              token: 'tokentokentoken',
              expires: 33303137868000,
            },
          })
        );
        mockAuth0User();
        nextRoute();
      } else {
        if (!process.env.STORYBOOK_TEST && !isSyntheticTest) {
          analytics.track(applicationFlow === 'bcm' ? 'Apply BCM Form - Login Request' : 'Apply Form - Login Request', {
            type: 'Info step submitted',
          });
        }
        if (changeStepTo && isOpenedInIframe) {
          changeStepTo(ApplyWizardSteps[STEP_INTEGRATED_LOGIN].id);
        } else {
          auth0.loginWithRedirect({
            authorizationParams: {
              connection: 'email',
              login_hint: formValues.owner_1_email,
              screen_hint: 'signup',
              'user[given_name]': formValues.owner_1_first,
              'user[family_name]': formValues.owner_1_last,
              'user[phone_number]': formValues.owner_1_mobile,
            },
            appState: {
              returnTo: '/app/verify/login',
            },
          });
        }
      }
    } else {
      // Is authenticated
      if (allValues.business_id) {
        await updateOpportunity({
          data: {
            business: {
              name: formValues.business_name,
              contacts: [
                {
                  id: contact_id,
                  email: formValues.owner_1_email,
                  firstName: formValues.owner_1_first,
                  lastName: formValues.owner_1_last,
                  phoneMobile: formValues.owner_1_mobile,
                },
              ],
            },
          },
        });
        nextRoute();
      } else {
        if (applicationFlow === 'bcm') {
          if (
            allValues.business_name &&
            allValues.owner_1_email &&
            allValues.owner_1_mobile &&
            allValues.owner_1_first &&
            allValues.owner_1_last
          ) {
            // No account id but data present so it needs to be saved
            history.push(`/bcm/app/create`);
          } else {
            // No acount id and no deal data - fresh login (from homepage likely)
            history.push(`/bcm/business/select`);
          }
        } else {
          if (
            allValues.business_name &&
            allValues.owner_1_email &&
            allValues.owner_1_mobile &&
            allValues.owner_1_first &&
            allValues.owner_1_last &&
            allValues.loan_amount &&
            allValues.loan_terms
          ) {
            // No deal id but data present so it needs to be saved
            history.push(`/app/create`);
          } else {
            // No deal id and no deal data - fresh login (from homepage likely)
            history.push(`/app/select`);
          }
        }
      }
    }
  };

  return (
    <Info
      onSubmit={saveInfo}
      {...{
        user,
        dispatch,
        loanType,
        type,
        showProcessingModal,
        dataLayerPush,
        saving: dealUpdateLoading || saving,
        callApi,
        setSaving,
        ...other,
      }}
    />
  );
};

const appSelector = formValueSelector('application');
const loginSelector = formValueSelector('login');

const mapStateToProps = (state) => {
  return {
    allValues: getFormValues('application')(state) || {},
    finalWizardRoutes: state.wizardRouting.finalWizardRoutes,
    loanAmount: appSelector(state, 'loan_amount'),
    loanType: appSelector(state, 'loan_type') || ['UNKNOWN'],
    loanTerms: appSelector(state, 'loan_terms'),
    loanPayment: appSelector(state, 'loan_payment'),
    equipment: appSelector(state, 'equipment_description'),
    login_id: loginSelector(state, 'login_id'),
    contact_id: appSelector(state, 'owner_1_id'),
    user: state.user,
    config: state.config,
    vendor_name: state.brand?.data?.vendor_name,
    routes: state.brand?.data?.routes,
    formValues: state.form.application.values,
  };
};

const InfoContainerWrapped = connect(mapStateToProps)(withRouter(InfoContainer));
export default InfoContainerWrapped;
