import DOMPurify from 'isomorphic-dompurify';
import queryString from 'query-string';
import { emailAddress, fedTaxId, phoneNumber, required } from './components/_fields/validation';
import { change } from 'redux-form';
import { changePriceToNumber } from './utils/changePriceToNumber';
import { estPaymentByApr } from '@utils/paymentCalculations';
import { setBrandRoutes, setHiddenRoutes } from './redux/actions';
import { normalizePhoneNumber } from './utils/normalizePhoneNumber';
import { apiService } from './services/api.service';
import { backendBaseUrl } from './utils/platformBasedInfo';
import {
  ApplyWizardSteps,
  FIELD_VALIDATION,
  STEP_INTEGRATED_LOGIN,
  stringWithContent,
} from '@components/ApplyWizard/ApplyWizardSteps';
import { z } from 'zod';

interface Owner {
  idx: string;
  first: string;
  last: string;
  mobile: string;
  email: string;
  title: string;
  ownership: number;
  ssn: string;
  dob: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  suite?: string;
}

interface FormValues {
  [key: string]: any;
  loan_amount?: number;
  loan_terms?: number;
  loan_type?: string;
  equipment_description?: string;
  business_name?: string;
  owner_1_first?: string;
  owner_1_last?: string;
  owner_1_mobile?: string;
  owner_1_email?: string;
  business_years?: number;
  business_revenue?: number;
  business_phone?: string;
  business_street?: string;
  business_city?: string;
  business_zip?: string;
  business_state?: string;
  home_based?: boolean;
  naics_keyword?: string;
  business_structure?: string;
  business_ein?: string;
  business_website?: string;
}

interface PrefillStepsConfig {
  routes: string[];
  stepsToHide: string[];
  finalInAppValues: FormValues;
  user: any;
  callApi: boolean;
  dataLayerPush: (data: any) => void;
  vendor_name: string;
  loanId: string;
  auth0: any;
  history: any;
  isOpenedInIframe: boolean;
  changeStepTo: string;
}

const buildOwners = (formValues: FormValues): Owner[] => {
  let owners: Owner[] = [];

  Object.keys(formValues).map((key) => {
    if (key.toString().match(/owner(.*)/)) {
      const match = key.match(/owner_(\d+)_/);
      if (!match) {
        return;
      }
      const ownerNumber = match[1];

      const isAlreadyAdded = owners.some((owner) => owner.idx === ownerNumber);
      if (isAlreadyAdded) {
        return;
      }

      owners = [
        ...owners,
        {
          idx: ownerNumber,
          first: formValues[`owner_${ownerNumber}_first`],
          last: formValues[`owner_${ownerNumber}_last`],
          mobile: formValues[`owner_${ownerNumber}_mobile`],
          email: formValues[`owner_${ownerNumber}_email`],
          title: formValues[`owner_${ownerNumber}_title`],
          ownership: Number(formValues[`owner_${ownerNumber}_ownership`]),
          ssn: formValues[`owner_${ownerNumber}_ssn`],
          dob: formValues[`owner_${ownerNumber}_dob`],
          street: formValues[`owner_${ownerNumber}_street`],
          city: formValues[`owner_${ownerNumber}_city`],
          state: formValues[`owner_${ownerNumber}_state`],
          zip: formValues[`owner_${ownerNumber}_zip`],
          suite: formValues[`owner_${ownerNumber}_suite`],
        },
      ];
    }
  });

  return owners;
};

const validateOwner = (owner: Owner): boolean => {
  if (
    required(owner.first) ||
    required(owner.last) ||
    required(owner.mobile) ||
    phoneNumber(owner.mobile) ||
    required(owner.email) ||
    emailAddress(owner.email) ||
    required(owner.title) ||
    required(owner.ownership) ||
    required(owner.ssn) ||
    required(owner.dob) ||
    required(owner.street) ||
    required(owner.city) ||
    required(owner.state) ||
    required(owner.zip) ||
    !states.includes(owner.state)
  ) {
    console.log(
      'INVALID OWNER:::: ',
      owner,
      required(owner.first),
      required(owner.last),
      required(owner.mobile),
      phoneNumber(owner.mobile),
      required(owner.email),
      emailAddress(owner.email),
      required(owner.title),
      required(owner.ownership),
      required(owner.ssn),
      required(owner.dob),
      required(owner.street),
      required(owner.city),
      required(owner.state),
      required(owner.zip),
      !states.includes(owner.state)
    );
    return false;
  } else {
    return true;
  }
};

const removeParam = (
  param: string,
  object: {
    [a: string]: any;
  }
): { [key: string]: any } => {
  const { [param]: _, ...rest } = object;
  return rest;
};

export const removeFormParams = (params: { [key: string]: any }, paramsToBeRemoved: string[], history: any): void => {
  if (Object.keys(paramsToBeRemoved).length > 0) {
    const restParams = paramsToBeRemoved.reduce(
      (acc, paramName) => {
        return removeParam(paramName, acc);
      },
      { ...params }
    );

    const newUrlParams = queryString.stringify(restParams);
    const newUrl = `${location.pathname}?${newUrlParams}`;
    history.replace(newUrl);
  }
};

const fillOtherOwnerInfo = (
  params: { [key: string]: any },
  paramsToBeRemoved: string[],
  ownerNumber: string,
  dispatch: any
): void => {
  const ownerKeys = [
    'first',
    'last',
    'mobile',
    'email',
    'title',
    'ownership',
    'dob',
    'dob_month',
    'dob_day',
    'dob_year',
    'ssn',
    'street',
    'city',
    'zip',
    'state',
    'suite',
  ];

  for (const key of ownerKeys) {
    const ownerKey = `owner_${ownerNumber}_${key}`;

    if (ownerKey in params && params[ownerKey]) {
      const value =
        key === 'mobile'
          ? normalizePhoneNumber(params[ownerKey])
          : key === 'dob_month' || key === 'dob_day' || key === 'dob_year' || key === 'ownership'
          ? Number(params[ownerKey])
          : params[ownerKey];
      dispatch(change('application', ownerKey, value));
      dispatch(change('prefilled', ownerKey, value));
      paramsToBeRemoved.push(ownerKey);
    }
  }
};

const sanitizeInput = (input: any): any => {
  if (typeof input === 'string') {
    return DOMPurify.sanitize(input, { ALLOWED_TAGS: [], ALLOWED_ATTR: [] });
  }
  if (typeof input === 'number') {
    return input;
  }
  if (Array.isArray(input)) {
    return input.map((item) => sanitizeInput(item));
  }
  if (typeof input === 'object' && input !== null) {
    const sanitizedObj: { [key: string]: any } = {};
    Object.entries(input).forEach(([key, value]) => {
      sanitizedObj[key] = sanitizeInput(value);
    });
    return sanitizedObj;
  }
  return input;
};

export const prefillSteps = async (
  params: { [key: string]: any },
  dispatch: any,
  config: PrefillStepsConfig,
  history: any
): Promise<void> => {
  const sanitizedParams = sanitizeInput(params);

  const {
    price,
    loan_amount,
    loan_terms,
    loan_type,
    title,
    equipment_description,

    business_name,
    owner_1_first,
    first_name,
    owner_1_last,
    last_name,
    owner_1_mobile,
    phone,
    owner_1_email,
    email,
    consent,

    business_years,
    year,
    business_revenue,
    business_phone,
    business_street,
    address,
    business_city,
    city,
    business_zip,
    zip_code,
    business_state,
    state,
    business_suite,
    naics_code,
    business_dba,

    business_structure,
    business_ein,
    business_website,
    owner_1_title,
    owner_1_ownership,
    owner_1_dob,
    owner_1_dob_month,
    owner_1_dob_day,
    owner_1_dob_year,
    owner_1_ssn_masked,
    owner_1_ssn,
    owner_1_street,
    owner_1_city,
    owner_1_zip,
    owner_1_state,
    owner_1_suite,
    home_based,
    when_funding_needed,

    partnerSystemUserId,
    partnerSystemAccountId,
    referralPartnerId,
  } = sanitizedParams;

  const paramsToBeRemoved = [];

  if (price || loan_amount) {
    const amount = loan_amount || price;
    const amountNumber = changePriceToNumber(amount);
    const validationResultLoanAmount = FIELD_VALIDATION.loan_amount.safeParse(amountNumber);
    if (validationResultLoanAmount.success) {
      dispatch(change('application', 'loan_amount', amountNumber));
      dispatch(change('prefilled', 'loan_amount', amountNumber));
      if (price) {
        paramsToBeRemoved.push('price');
      }
      if (loan_amount) {
        paramsToBeRemoved.push('loan_amount');
      }
    }
  }

  if (
    loan_terms === '24' ||
    loan_terms === 24 ||
    loan_terms === '36' ||
    loan_terms === 36 ||
    loan_terms === '48' ||
    loan_terms === 48 ||
    loan_terms === '60' ||
    loan_terms === 60
  ) {
    const validationResult = FIELD_VALIDATION.loan_terms.safeParse(Number(loan_terms));
    if (validationResult.success) {
      dispatch(change('application', 'loan_terms', Number(loan_terms)));
      dispatch(change('prefilled', 'loan_terms', Number(loan_terms)));
      paramsToBeRemoved.push('loan_terms');
    }
  }

  if (loan_type) {
    if (
      loan_type === 'WC' ||
      loan_type === 'EQ' ||
      loan_type === 'LOC' ||
      loan_type === 'BCC' ||
      loan_type === 'PL' ||
      loan_type === 'BCM'
    ) {
      dispatch(change('application', 'loan_type', [loan_type]));
      dispatch(change('prefilled', 'loan_type', [loan_type]));
      paramsToBeRemoved.push('loan_type');
    } else if (loan_type.includes(',')) {
      const loan_types = loan_type
        .split(',')
        .filter(
          (loan_type: any) =>
            loan_type === 'WC' ||
            loan_type === 'EQ' ||
            loan_type === 'LOC' ||
            loan_type === 'BCC' ||
            loan_type === 'PL' ||
            loan_type === 'BCM'
        );
      dispatch(change('application', 'loan_type', loan_types));
      dispatch(change('prefilled', 'loan_type', loan_types));
      paramsToBeRemoved.push('loan_type');
    }
  }

  if (loan_amount && loan_terms && loan_type) {
    const paymentAmount = estPaymentByApr(
      loan_amount,
      loan_terms,
      (config as any)[loan_type?.toUpperCase() === 'WC' ? 'WC' : loan_type?.toUpperCase() === 'EQ' ? 'EQ' : 'WC'].rate
    );

    dispatch(change('application', 'loan_payment', paymentAmount));
    dispatch(change('prefilled', 'loan_payment', paymentAmount));
    paramsToBeRemoved.push('loan_payment');
  }

  if (title || equipment_description) {
    const description = equipment_description || title;

    const validationResult = z
      .string()
      .min(2, 'Please enter a valid description.')
      .refine(...stringWithContent())
      .safeParse(description);
    if (validationResult.success) {
      dispatch(change('application', 'equipment_description', description));
      dispatch(change('prefilled', 'equipment_description', description));
      if (title) {
        paramsToBeRemoved.push('title');
      }
      if (equipment_description) {
        paramsToBeRemoved.push('equipment_description');
      }
    }
  }

  if (business_name) {
    const validationResult = FIELD_VALIDATION.business_name.safeParse(business_name);
    if (validationResult.success) {
      dispatch(change('application', 'business_name', business_name));
      dispatch(change('prefilled', 'business_name', business_name));
      paramsToBeRemoved.push('business_name');
    }
  }

  if (partnerSystemUserId) {
    dispatch(change('application', 'partnerSystemUserId', partnerSystemUserId));
    dispatch(change('prefilled', 'partnerSystemUserId', partnerSystemUserId));
    paramsToBeRemoved.push('partnerSystemUserId');
  }

  if (partnerSystemAccountId) {
    dispatch(change('application', 'partnerSystemAccountId', partnerSystemAccountId));
    dispatch(change('prefilled', 'partnerSystemAccountId', partnerSystemAccountId));
    paramsToBeRemoved.push('partnerSystemAccountId');
  }

  if (referralPartnerId) {
    dispatch(change('application', 'referralPartnerId', referralPartnerId));
    dispatch(change('prefilled', 'referralPartnerId', referralPartnerId));
    paramsToBeRemoved.push('referralPartnerId');
  }

  if (
    when_funding_needed === 'immediately' ||
    when_funding_needed === 'within-more-than-30-days' ||
    when_funding_needed === 'within-1-month' ||
    when_funding_needed === 'within-10-days'
  ) {
    dispatch(change('application', 'when_funding_needed', when_funding_needed));
    dispatch(change('prefilled', 'when_funding_needed', when_funding_needed));
    paramsToBeRemoved.push('when_funding_needed');
  }

  if (business_dba) {
    dispatch(change('application', 'business_dba', business_dba));
    dispatch(change('prefilled', 'business_dba', business_dba));
    paramsToBeRemoved.push('business_dba');
  }

  if (owner_1_first || first_name) {
    const first = owner_1_first || first_name;
    const validationResult = FIELD_VALIDATION.owner_1_first.safeParse(first);
    if (validationResult.success) {
      dispatch(change('application', 'owner_1_first', first));
      dispatch(change('prefilled', 'owner_1_first', first));
      if (owner_1_first) {
        paramsToBeRemoved.push('owner_1_first');
      }
      if (first_name) {
        paramsToBeRemoved.push('first_name');
      }
    }
  }

  if (owner_1_last || last_name) {
    const last = owner_1_last || last_name;
    const validationResult = FIELD_VALIDATION.owner_1_last.safeParse(last);
    if (validationResult.success) {
      dispatch(change('application', 'owner_1_last', last));
      dispatch(change('prefilled', 'owner_1_last', last));
      if (owner_1_last) {
        paramsToBeRemoved.push('owner_1_last');
      }
      if (last_name) {
        paramsToBeRemoved.push('last_name');
      }
    }
  }

  if (owner_1_mobile || phone) {
    const ownerPhone = owner_1_mobile || phone;
    const validationResult = FIELD_VALIDATION.owner_1_mobile.safeParse(normalizePhoneNumber(ownerPhone));
    if (validationResult.success) {
      dispatch(change('application', 'owner_1_mobile', normalizePhoneNumber(ownerPhone)));
      dispatch(change('prefilled', 'owner_1_mobile', normalizePhoneNumber(ownerPhone)));
      if (owner_1_mobile) {
        paramsToBeRemoved.push('owner_1_mobile');
      }
      if (phone) {
        paramsToBeRemoved.push('phone');
      }
    }
  }

  if (owner_1_email || email) {
    const owner1Email = owner_1_email || email;
    const validationResult = FIELD_VALIDATION.owner_1_email.safeParse(owner1Email);
    if (validationResult.success) {
      dispatch(change('application', 'owner_1_email', owner1Email));
      dispatch(change('prefilled', 'owner_1_email', owner1Email));
      if (owner_1_email) {
        paramsToBeRemoved.push('owner_1_email');
      }
      if (email) {
        paramsToBeRemoved.push('email');
      }
    }
  }

  if (
    (owner_1_first || first_name) &&
    (owner_1_last || last_name) &&
    (owner_1_mobile || phone) &&
    (owner_1_email || email)
  ) {
    dispatch(change('application', 'consent', true));
    dispatch(change('prefilled', 'consent', true));
    paramsToBeRemoved.push('');
  }

  if (business_years || year) {
    var tib = business_years || year;
    const validationResult = FIELD_VALIDATION.business_years.safeParse(Number(tib));
    if (validationResult.success) {
      dispatch(change('application', 'business_years', Number(tib)));
      dispatch(change('prefilled', 'business_years', Number(tib)));
      if (business_years) {
        paramsToBeRemoved.push('business_years');
      }
      if (year) {
        paramsToBeRemoved.push('year');
      }
    }
  }

  if (business_revenue) {
    const validationResult = FIELD_VALIDATION.business_revenue.safeParse(Number(business_revenue));
    if (validationResult.success) {
      dispatch(change('application', 'business_revenue', Number(business_revenue)));
      dispatch(change('prefilled', 'business_revenue', Number(business_revenue)));
      paramsToBeRemoved.push('business_revenue');
    }
  }

  if (business_phone) {
    const validationResult = FIELD_VALIDATION.business_phone.safeParse(normalizePhoneNumber(business_phone));
    if (validationResult.success) {
      dispatch(change('application', 'business_phone', normalizePhoneNumber(business_phone)));
      dispatch(change('prefilled', 'business_phone', normalizePhoneNumber(business_phone)));
      paramsToBeRemoved.push('business_phone');
    }
  }

  if (business_street || address) {
    const street = business_street || address;
    const validationResult = FIELD_VALIDATION.business_street.safeParse(street);
    if (validationResult.success) {
      dispatch(change('application', 'business_street', street));
      dispatch(change('prefilled', 'business_street', street));
      if (business_street) {
        paramsToBeRemoved.push('business_street');
      }
      if (address) {
        paramsToBeRemoved.push('address');
      }
    }
  }

  if (business_suite) {
    const validationResult = FIELD_VALIDATION.business_suite.safeParse(business_suite);
    if (validationResult.success) {
      dispatch(change('application', 'business_suite', business_suite));
      dispatch(change('prefilled', 'business_suite', business_suite));
      paramsToBeRemoved.push('business_suite');
    }
  }

  if (business_city || city) {
    const businessCity = business_city || city;
    const validationResult = FIELD_VALIDATION.business_city.safeParse(businessCity);
    if (validationResult.success) {
      dispatch(change('application', 'business_city', businessCity));
      dispatch(change('prefilled', 'business_city', businessCity));
      if (business_city) {
        paramsToBeRemoved.push('business_city');
      }
      if (city) {
        paramsToBeRemoved.push('city');
      }
    }
  }

  if (business_zip || zip_code) {
    const zip = business_zip || zip_code;
    const validationResult = FIELD_VALIDATION.business_zip.safeParse(zip);
    if (validationResult.success) {
      dispatch(change('application', 'business_zip', zip));
      dispatch(change('prefilled', 'business_zip', zip));
      if (business_zip) {
        paramsToBeRemoved.push('business_zip');
      }
      if (zip_code) {
        paramsToBeRemoved.push('zip_code');
      }
    }
  }

  if (business_state) {
    const validationResult = FIELD_VALIDATION.business_state.safeParse(business_state);
    if (validationResult.success) {
      dispatch(change('application', 'business_state', business_state));
      dispatch(change('prefilled', 'business_state', business_state));
      paramsToBeRemoved.push('business_state');
    }
  }
  if (business_suite) {
    const validationResult = FIELD_VALIDATION.business_suite.safeParse(business_suite);
    if (validationResult.success) {
      dispatch(change('application', 'business_suite', business_suite));
      dispatch(change('prefilled', 'business_suite', business_suite));
      paramsToBeRemoved.push('business_suite');
    }
  }

  if (naics_code) {
    const validationResult = FIELD_VALIDATION.business_naics.safeParse(Number(naics_code));
    if (validationResult.success) {
      dispatch(change('application', 'naics_keyword', Number(naics_code))); // Probably obsolete, likely only used in Apply 1.0
      dispatch(change('prefilled', 'naics_keyword', Number(naics_code))); // Probably obsolete, likely only used in Apply 1.0
      dispatch(change('application', 'business_naics', Number(naics_code)));
      dispatch(change('prefilled', 'business_naics', Number(naics_code)));
      paramsToBeRemoved.push('naics_code');
    }
  }

  if (business_structure) {
    const validationResult = FIELD_VALIDATION.business_structure.safeParse(business_structure);
    if (validationResult.success) {
      dispatch(change('application', 'business_structure', business_structure));
      dispatch(change('prefilled', 'business_structure', business_structure));
      paramsToBeRemoved.push('business_structure');
    }
  }

  if (business_ein) {
    const validationResult = FIELD_VALIDATION.business_ein.safeParse(business_ein);
    if (validationResult.success) {
      dispatch(change('application', 'business_ein', business_ein));
      dispatch(change('prefilled', 'business_ein', business_ein));
      paramsToBeRemoved.push('business_ein');
    }
  }

  if (business_website) {
    const validationResult = FIELD_VALIDATION.business_website.safeParse(business_website);
    if (validationResult.success) {
      dispatch(change('application', 'business_website', business_website));
      dispatch(change('prefilled', 'business_website', business_website));
      paramsToBeRemoved.push('business_website');
    }
  }

  if (owner_1_title) {
    const validationResult = FIELD_VALIDATION.owner_1_title.safeParse(owner_1_title);
    if (validationResult.success) {
      dispatch(change('application', 'owner_1_title', owner_1_title));
      dispatch(change('prefilled', 'owner_1_title', owner_1_title));
      paramsToBeRemoved.push('owner_1_title');
    }
  }

  if (owner_1_ownership) {
    const validationResult = FIELD_VALIDATION.owner_ownership.safeParse(Number(owner_1_ownership));
    if (validationResult.success) {
      dispatch(change('application', 'owner_1_ownership', Number(owner_1_ownership)));
      dispatch(change('prefilled', 'owner_1_ownership', Number(owner_1_ownership)));
      paramsToBeRemoved.push('owner_1_ownership');
    }
  }

  if (owner_1_dob) {
    const validationResult = z
      .string()
      .regex(/^\d{2}\/\d{2}\/\d{4}$/, 'Invalid, must be mm/dd/yyyy format')
      .safeParse(owner_1_dob);
    if (validationResult.success) {
      dispatch(change('application', 'owner_1_dob', owner_1_dob));
      dispatch(change('prefilled', 'owner_1_dob', owner_1_dob));
      paramsToBeRemoved.push('owner_1_dob');
    }
  }

  if (owner_1_dob_day) {
    const validationResult = FIELD_VALIDATION.owner_dob_day.safeParse(Number(owner_1_dob_day));
    if (validationResult.success) {
      dispatch(change('application', 'owner_1_dob_day', Number(owner_1_dob_day)));
      dispatch(change('prefilled', 'owner_1_dob_day', Number(owner_1_dob_day)));
      paramsToBeRemoved.push('owner_1_dob_day');
    }
  }

  if (owner_1_dob_month) {
    const validationResult = FIELD_VALIDATION.owner_dob_month.safeParse(Number(owner_1_dob_month));
    if (validationResult.success) {
      dispatch(change('application', 'owner_1_dob_month', Number(owner_1_dob_month)));
      dispatch(change('prefilled', 'owner_1_dob_month', Number(owner_1_dob_month)));
      paramsToBeRemoved.push('owner_1_dob_month');
    }
  }

  if (owner_1_dob_year) {
    const validationResult = FIELD_VALIDATION.owner_dob_year.safeParse(Number(owner_1_dob_year));
    if (validationResult.success) {
      dispatch(change('application', 'owner_1_dob_year', Number(owner_1_dob_year)));
      dispatch(change('prefilled', 'owner_1_dob_year', Number(owner_1_dob_year)));
      paramsToBeRemoved.push('owner_1_dob_year');
    }
  }

  if (owner_1_ssn) {
    const validationResult = FIELD_VALIDATION.owner_ssn.safeParse(owner_1_ssn);
    if (validationResult.success) {
      dispatch(change('application', 'owner_1_ssn', owner_1_ssn));
      dispatch(change('prefilled', 'owner_1_ssn', owner_1_ssn));
      paramsToBeRemoved.push('owner_1_ssn');
    }
  }

  if (owner_1_street) {
    const validationResult = FIELD_VALIDATION.owner_1_street.safeParse(owner_1_street);
    if (validationResult.success) {
      dispatch(change('application', 'owner_1_street', owner_1_street));
      dispatch(change('prefilled', 'owner_1_street', owner_1_street));
      paramsToBeRemoved.push('owner_1_street');
    }
  }

  if (owner_1_suite) {
    const validationResult = FIELD_VALIDATION.owner_1_suite.safeParse(owner_1_suite);
    if (validationResult.success) {
      dispatch(change('application', 'owner_1_suite', owner_1_suite));
      dispatch(change('prefilled', 'owner_1_suite', owner_1_suite));
      paramsToBeRemoved.push('owner_1_suite');
    }
  }

  if (owner_1_city) {
    const validationResult = FIELD_VALIDATION.owner_1_city.safeParse(owner_1_city);
    if (validationResult.success) {
      dispatch(change('application', 'owner_1_city', owner_1_city));
      dispatch(change('prefilled', 'owner_1_city', owner_1_city));
      paramsToBeRemoved.push('owner_1_city');
    }
  }

  if (owner_1_state) {
    const validationResult = FIELD_VALIDATION.owner_1_state.safeParse(owner_1_state);
    if (validationResult.success) {
      dispatch(change('application', 'owner_1_state', owner_1_state));
      dispatch(change('prefilled', 'owner_1_state', owner_1_state));
      paramsToBeRemoved.push('owner_1_state');
    }
  }

  if (owner_1_zip) {
    const validationResult = FIELD_VALIDATION.owner_1_zip.safeParse(owner_1_zip);
    if (validationResult.success) {
      dispatch(change('application', 'owner_1_zip', owner_1_zip));
      dispatch(change('prefilled', 'owner_1_zip', owner_1_zip));
      paramsToBeRemoved.push('owner_1_zip');
    }
  }

  if (home_based === 'true' || home_based === true) {
    const street = business_street || address;
    const businessCity = business_city || city;
    const zip = business_zip || zip_code;
    const businessState = business_state || state;

    dispatch(change('application', 'home_based', true));
    dispatch(change('prefilled', 'home_based', true));

    dispatch(change('application', 'owner_1_street', street));
    dispatch(change('application', 'owner_1_city', businessCity));
    dispatch(change('application', 'owner_1_zip', zip));
    dispatch(change('application', 'owner_1_state', businessState));
    dispatch(change('application', 'owner_1_suite', business_suite));

    dispatch(change('prefilled', 'owner_1_street', street));
    dispatch(change('prefilled', 'owner_1_city', businessCity));
    dispatch(change('prefilled', 'owner_1_zip', zip));
    dispatch(change('prefilled', 'owner_1_state', businessState));
    dispatch(change('prefilled', 'owner_1_suite', business_suite));

    paramsToBeRemoved.push('home_based');
  }

  let ownerNumber = 2;

  while (true) {
    const ownerKeyPrefix = `owner_${ownerNumber}`;
    const ownerExists = Object.keys(sanitizedParams).some((key) => key.startsWith(ownerKeyPrefix));

    if (!ownerExists) {
      break;
    }

    fillOtherOwnerInfo(sanitizedParams, paramsToBeRemoved, ownerNumber as any, dispatch);
    ownerNumber++;
  }

  await new Promise((resolve) => {
    resolve(true);
  });

  removeFormParams(sanitizedParams, paramsToBeRemoved, history);
};

interface HideStepsParams {
  dispatch: any;
  stepsToHide: string[];
  routes: string[];
  formValues: FormValues;
  finalInAppValues: FormValues;
  user: any;
  callApi: boolean;
  dataLayerPush: (data: any) => void;
  vendor_name: string;
  loanId: string;
  auth0: any;
  history: any;
  isOpenedInIframe: boolean;
  changeStepTo: (step: string) => void;
}

export const hideSteps = async ({
  dispatch,
  stepsToHide,
  routes,
  formValues,
  finalInAppValues,
  user,
  callApi,
  dataLayerPush,
  vendor_name,
  loanId,
  auth0,
  history,
  isOpenedInIframe,
  changeStepTo,
}: HideStepsParams) => {
  const hiddenRoutes = stepsToHide
    .filter((idToHide) => !!flowSteps.find((route) => route.id === idToHide))
    .map((idToHide) => flowSteps.find((route) => route.id === idToHide))
    .filter((routeToHide) => routeToHide && routeToHide.validate({ ...formValues, loanId }));

  const filteredRoutes = [...routes].filter(
    (r) =>
      !hiddenRoutes.some((hiddenRoute) =>
        (Array.isArray(hiddenRoute?.path) ? hiddenRoute.path : [hiddenRoute?.path]).includes(r)
      )
  );

  dispatch(setBrandRoutes(filteredRoutes));

  await Promise.all(
    hiddenRoutes.map(async (routeToHide) => {
      if (routeToHide?.onHide) {
        await routeToHide.onHide(
          dispatch,
          callApi,
          dataLayerPush,
          vendor_name,
          auth0,
          filteredRoutes,
          history,
          isOpenedInIframe,
          changeStepTo
        )(formValues, user, finalInAppValues);
      }
    })
  );

  return filteredRoutes;
};

interface FlowStep {
  id: string;
  path: string | string[];
  validate: (values: FormValues) => boolean | undefined;
  onHide?: (
    dispatch: any,
    callApi: boolean,
    dataLayerPush: (data: any) => void,
    vendor_name: string,
    auth0: any,
    routes: string[],
    history: any,
    isOpenedInIframe: boolean,
    changeStepTo: (step: string) => void
  ) => (toHideValues: any, user: any, finalInAppValues: any) => void;
}

export const flowSteps: FlowStep[] = [
  {
    id: 'amountStep',
    path: 'amount',
    validate: ({ loan_amount, loan_terms, loan_type }) => {
      // loan_payment is caluclated

      if (required(loan_amount) || required(loan_terms) || required(loan_type)) {
        return false;
      } else {
        return true;
      }
    },
  },

  {
    id: 'equipmentStep',
    path: 'equipment',
    validate: ({ equipment_description }) => {
      if (required(equipment_description)) {
        return false;
      } else {
        return true;
      }
    },
  },
  {
    id: 'infoStep',
    path: 'info',
    validate: ({ business_name, owner_1_first, owner_1_last, owner_1_mobile, owner_1_email }) => {
      if (
        required(business_name) ||
        required(owner_1_first) ||
        required(owner_1_last) ||
        required(owner_1_mobile) ||
        phoneNumber(owner_1_mobile) ||
        required(owner_1_email) ||
        emailAddress(owner_1_email)
      ) {
        return false;
      } else {
        return true;
      }
    },
    onHide:
      (dispatch, callApi, dataLayerPush, vendor_name, auth0, routes, history, isOpenedInIframe, changeStepTo) =>
      async (toHideValues: any, user: any, finalInAppValues: any) => {
        if (!auth0.isAuthenticated) {
          if (changeStepTo && isOpenedInIframe) {
            changeStepTo(ApplyWizardSteps[STEP_INTEGRATED_LOGIN].id);
          } else {
            auth0.loginWithRedirect({
              authorizationParams: {
                connection: 'email',
                screen_hint: 'signup',
              },
              appState: {
                returnTo: '/app/verify/login',
              },
            });
          }
        } else {
          const index = routes.findIndex((route) => route.includes('verify/login'));

          if (index !== -1) {
            history.push(`/app/${routes[index + 1]}`);
          }
        }
      },
  },

  // LOGGED IN STEPS:
  {
    id: 'businessYearsStep',
    path: 'business/years',
    validate: ({ business_years, loanId }) => {
      if (!loanId) {
        return false;
      }
      if (required(business_years)) {
        return false;
      } else {
        return true;
      }
    },
  },
  {
    id: 'businessRevenueStep',
    path: 'business/revenue',
    validate: ({ business_revenue, loanId }) => {
      if (!loanId) {
        return false;
      }
      if (required(business_revenue)) {
        return false;
      } else {
        return true;
      }
    },
  },
  {
    id: 'businessPhoneStep',
    path: 'business/phone',
    validate: ({ business_phone, loanId }) => {
      if (!loanId) {
        return false;
      }
      if (required(business_phone) || phoneNumber(business_phone)) {
        return false;
      } else {
        return true;
      }
    },
  },
  {
    id: 'businessAddressStep',
    path: 'business/address',
    validate: ({ business_street, business_city, business_zip, business_state, loanId }) => {
      if (!loanId) {
        return false;
      }
      if (
        required(business_street) ||
        required(business_city) ||
        required(business_zip) ||
        required(business_state) ||
        !states.includes(business_state as string)
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  {
    id: 'businessBasedStep',
    path: 'business/based',
    validate: ({ home_based, loanId }) => {
      if (!loanId) {
        return false;
      }
      if (required(home_based)) {
        return false;
      } else {
        return true;
      }
    },
  },
  {
    id: 'businessNaicsStep',
    path: 'business/naics',
    validate: ({ naics_keyword, loanId }) => {
      if (!loanId) {
        return false;
      }
      if (required(naics_keyword)) {
        return false;
      } else {
        return true;
      }
    },
  },
  {
    id: 'businessStructureStep',
    path: 'business/structure',
    validate: ({ business_structure, loanId }) => {
      if (!loanId) {
        return false;
      }
      if (required(business_structure)) {
        return false;
      } else {
        return true;
      }
    },
  },
  {
    id: 'businessEinStep',
    path: 'business/ein',
    validate: ({ business_ein, loanId }) => {
      if (!loanId) {
        return false;
      }
      if (required(business_ein) || !fedTaxId(business_ein)) {
        return false;
      } else {
        return true;
      }
    },
  },
  {
    id: 'businessWebsiteStep',
    path: 'business/website',
    validate: ({ business_website, loanId }) => {
      if (!loanId) {
        return false;
      }
      return true;
    }, // OPTIONAL
  },
  {
    id: 'ownersInfoSteps',
    path: [
      'owners/:idx/info',
      'owners/:idx/ownership',
      'owners/:idx/address',
      'owners/:idx/identity',
      'owners/:idx/add',
    ],
    validate: (formValues) => {
      if (!formValues.loanId) {
        return false;
      }
      const owners = buildOwners(formValues);
      const allOwnersValid = owners.every((owner) => validateOwner(owner));
      return allOwnersValid;
    },
  },

  // {
  //     id: 'decisionProcessStep', path: 'decision/process', validate: ({ }) => {
  //         if (required()) {
  //             return false
  //         } else {
  //             return true
  //         }
  //     }
  // },
  // {
  //     id: 'decisionPersonalStep', path: 'decision/personal', validate: ({ }) => {
  //         if (required()) {
  //             return false
  //         } else {
  //             return true
  //         }
  //     }
  // },
  // {
  //     id: 'decisionOffersStep', path: 'decision/offers', validate: ({ }) => {
  //         if (required()) {
  //             return false
  //         } else {
  //             return true
  //         }
  //     }
  // },
  // NOT HIDABLE
  // {
  //     id: 'bankConnectStep', path: 'bank/connect', validate: ({ }) => {
  //         if (required()) {
  //             return false
  //         } else {
  //             return true
  //         }
  //     }
  // },
  // {
  //     id: 'bankUploadStep', path: 'bank/upload', validate: ({ }) => {
  //         if (required()) {
  //             return false
  //         } else {
  //             return true
  //         }
  //     }
  // },
  // {
  //     id: 'bankEmailStep', path: 'bank/email', validate: ({ }) => {
  //         if (required()) {
  //             return false
  //         } else {
  //             return true
  //         }
  //     }
  // },
  // 3 ways of connecting bank: plaid / upload /send to mail
  // {
  //   id: 'agreementTermsStep',
  //   path: 'agreement/terms',
  //   validate: ({}) => {
  //     if (required()) {
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   },
  // },
  // {
  //   id: 'agreementSignStep',
  //   path: 'agreement/sign',
  //   validate: ({}) => {
  //     if (required()) {
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   },
  // },
  // {
  //   id: 'agreementUploadStep',
  //   path: 'agreement/upload',
  //   validate: ({}) => {
  //     if (required()) {
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   },
  // },
  // {
  //   id: 'agreementProblemStep',
  //   path: 'agreement/problem',
  //   validate: ({}) => {
  //     if (required()) {
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   },
  // },
  // {
  //     id: 'doneStep', path: 'done', validate: ({ }) => {
  //         if (required()) {
  //             return false
  //         } else {
  //             return true
  //         }
  //     }
  // },
];

const states = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export const saveHiddenDataAfterLogin = async (
  accountId: string,
  formValues: FormValues,
  contact1Id: string,
  vendor_name: string,
  user: any
): Promise<void> => {
  const data: any = {
    // product: 'EQ',
    // loanRequest: {
    //   amount: 37000,
    //   termLength: 60,
    //   termUnit: 'months',
    //   equipmentDesc: null,
    // },
    source: {
      // TODO: pass partner & brand info
      partnerId: 'partner-public-id',
      brandId: 'brand-public-id',
      // TODO: handle UTMs
      // campaign: {
      //   utmSource: 'utm_source',
      //   utmMedium: 'utm_medium',
      //   utmCampaign: 'utm_campaign',
      //   utmTerm: 'utm_term',
      //   utmContent: 'utm_content',
      // },
      customFields: {},
    },
    business: {
      id: accountId,
      // name: 'Creole Orleans Catering, LLC',
      // doingBusinessAs: null,
      entityType: formValues?.business_structure || null,
      taxIdNumber: formValues?.business_ein || null,
      naicsCode: formValues?.naics_keyword || null,
      // naicsDescription: 'test',
      annualRevenue: formValues?.business_revenue || null,
      // businessInceptionDate: '2020-01-13T00:00:00.000Z',
      phone: formValues?.business_phone || null,
      // mobile: '4049417980',
      // email: 'm.ali@creoleorleans.com',
      website: formValues?.business_website || null,
      // employees: 8,
      years: formValues?.business_years || null,
      address: {
        address1: formValues?.business_street || null,
        address2: formValues?.business_suite || null,
        city: formValues?.business_city || null,
        state: formValues?.business_state || null,
        postalCode: formValues?.business_zip || null,
      },
    },
    owners: [],
  };

  Object.keys(formValues).map((key) => {
    if (key.toString().match(/owner(.*)/)) {
      const match = key.match(/owner_(\d+)_/);
      if (match === null) {
        return;
      }
      const ownerNumber = match[1];

      const isAlreadyAdded = data.owners.some((owner: any) => owner.idx === ownerNumber);
      if (isAlreadyAdded) {
        return;
      }

      data.owners = [
        ...data.owners,
        {
          main: ownerNumber === '1' ? true : false,
          id: ownerNumber === '1' ? contact1Id : null,
          contactId: ownerNumber === '1' ? contact1Id : null,
          idx: ownerNumber,
          first: formValues[`owner_${ownerNumber}_first`],
          last: formValues[`owner_${ownerNumber}_last`],
          mobile: formValues[`owner_${ownerNumber}_mobile`],
          email: formValues[`owner_${ownerNumber}_email`],
          title: formValues[`owner_${ownerNumber}_title`],
          ownership: formValues[`owner_${ownerNumber}_ownership`],
          ssn: formValues[`owner_${ownerNumber}_ssn`],
          dob: formValues[`owner_${ownerNumber}_dob`],
          street: formValues[`owner_${ownerNumber}_street`],
          city: formValues[`owner_${ownerNumber}_city`],
          state: formValues[`owner_${ownerNumber}_state`],
          zip: formValues[`owner_${ownerNumber}_zip`],
          suite: formValues[`owner_${ownerNumber}_suite`],
        },
      ];
    }
  });
  data.contacts = data.owners; // TODO: remove owners when migrated to new name "contacts"

  await apiService.call('deals', data, vendor_name, user, { method: 'patch', baseUrl: backendBaseUrl });
};
