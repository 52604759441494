import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';
import { useIsInIframe, useWasOpenedByWindowOpen } from 'shared-components';

export const IframeOrWindowCleanupState = () => {
  const isOpenedInWindow = useWasOpenedByWindowOpen();
  const isOpenedInIframe = useIsInIframe();
  const dispatch = useDispatch();
  useEffect(() => {
    if (isOpenedInWindow || isOpenedInIframe) {
      dispatch(change('application', 'loan_id', ''));
      dispatch(change('application', 'business_id', ''));
      dispatch(change('application', 'contact_id', ''));
      dispatch(change('application', 'owner_1_id', ''));
      dispatch(change('application', 'owner_2_id', ''));
      dispatch(change('application', 'owner_3_id', ''));
      dispatch(change('application', 'owner_4_id', ''));
      dispatch(change('application', 'owner_5_id', ''));
      dispatch(change('application', 'owner_6_id', ''));
      dispatch(change('application', 'owner_7_id', ''));
    }
  }, []);
  return null;
};
