export const platformName = window.PLATFORM_NAME || process.env.PLATFORM_NAME;
export const appEnviroment = window.ENVIRONMENT || process.env.ENVIRONMENT;
export const voltBaseUrl = process.env.STORYBOOK_TEST
  ? 'somedomain.testtest'
  : window.VOLT_BASE_URL || (typeof process !== 'undefined' && process.env.VOLT_BASE_URL) || 'https://volt.tento.co';
export const backendBaseUrl = process.env.STORYBOOK_TEST
  ? 'somedomain.testtest'
  : window.BACKEND_BASE_URL || process.env.BACKEND_BASE_URL;
export const backendUrl = backendBaseUrl + '/api/apply';
export const auth0Domain =
  window.REACT_APP_AUTH0_DOMAIN ||
  process.env.REACT_APP_AUTH0_DOMAIN ||
  window.AUTH0_DOMAIN ||
  process.env.AUTH0_DOMAIN;
export const googleMapsApi = window.GOOGLE_MAPS_API_KEY || process.env.GOOGLE_MAPS_API_KEY;
export const auth0ClientId =
  window.REACT_APP_AUTH0_CLIENT_ID ||
  process.env.REACT_APP_AUTH0_CLIENT_ID ||
  window.AUTH0_CLIENT_ID ||
  process.env.AUTH0_CLIENT_ID;

export const applyBaseUrl = window.APPLY_BASE_URL || process.env.APPLY_BASE_URL;
export const brandId = window.BRAND_ID || process.env.BRAND_ID;
export const hostname = window.location.hostname;
export const hostnameParts = hostname.split('.');
export const isWoo = hostnameParts.length > 2 && hostnameParts[0] === 'woo';
export const isLocal = hostnameParts.length > 2 && hostnameParts[0] === 'local';
export const contactEmail = backendUrl.includes('tritoncptl')
  ? 'info@tritoncptl.com'
  : isWoo
  ? 'woo@tento.co'
  : 'hello@tento.co';
export const companyName = backendUrl.includes('tritoncptl') ? 'Triton' : 'Tento';

export const isSyntheticTest = window._DATADOG_SYNTHETICS_BROWSER !== undefined;
