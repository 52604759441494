import { getNumberOfOwnersFromFormValues } from '@components/Apply/OwnersMoreDetailsAboutYou/getNumberOfOwners';
import { SSNSchema } from '@components/validation/ssn';
import { RootState } from '@store/reducers';
import { z } from 'zod';
export const STEP_HOMEPAGE = '/';
export const STEP_BCM_HOMEPAGE = '/bcm';
export const STEP_BCM_INFO = '/bcm/info';
export const STEP_INTEGRATED_LOGIN = '/login';
export const STEP_BCM_BUSINESS_MORE_DETAILS = '/bcm/business/more-details';
export const STEP_BCM_SUMMARY = '/bcm/app/contact-soon';
export const STEP_AMOUNT = 'amount';
export const STEP_INFO = 'info';
export const STEP_VERIFY_LOGIN = 'app/verify/login';
export const STEP_BUSINESS_MORE_DETAILS = 'business/more-details';
export const STEP_BUSINESS_FURTHER_DETAILS = 'business/further-details';
export const STEP_BUSINESS_OWNERS = 'business/owners';
export const STEP_BANK_CONNECT = 'bank-connect';
export const STEP_TYPE_OF_FUNDING = 'type-of-funding';
export const STEP_WHEN_FUNDING_NEEDED = 'when-funding-needed';
export const STEP_SUMMARY = 'funding-is-almost-here';

export const stringWithContent = (message?: string): [(v: string | null | undefined) => boolean, string] => [
  (v: string | null | undefined) => (v ? v.trim().length > 0 : true),
  message || 'Cannot be empty or contain only whitespace characters',
];

export type ApplyWizardStep =
  | typeof STEP_HOMEPAGE
  | typeof STEP_BCM_HOMEPAGE
  | typeof STEP_BCM_INFO
  | typeof STEP_INTEGRATED_LOGIN
  | typeof STEP_BCM_BUSINESS_MORE_DETAILS
  | typeof STEP_BCM_SUMMARY
  | typeof STEP_AMOUNT
  | typeof STEP_INFO
  | typeof STEP_VERIFY_LOGIN
  | typeof STEP_BUSINESS_MORE_DETAILS
  | typeof STEP_BUSINESS_FURTHER_DETAILS
  | typeof STEP_BUSINESS_OWNERS
  | typeof STEP_BANK_CONNECT
  | typeof STEP_TYPE_OF_FUNDING
  | typeof STEP_WHEN_FUNDING_NEEDED
  | typeof STEP_SUMMARY;

export type ApplyWizardStepObject = {
  id: ApplyWizardStep;
  isHidden: boolean;
  isShowingInNavigation: boolean;
  path: string;
  zodSchema: z.ZodSchema<any>;
  isComplete: (state: RootState['form']) => boolean;
  Component: React.ComponentType<any>;
};

export const FIELD_VALIDATION = {
  loan_amount: z.number().positive(),
  loan_terms: z.number().min(1, 'Please select a loan term.'),
  business_name: z
    .string()
    .min(2, 'Please enter a valid business name.')
    .refine(...stringWithContent()),
  owner_1_first: z
    .string()
    .min(2, 'Please enter a valid first name.')
    .refine(...stringWithContent()),
  owner_1_last: z
    .string()
    .min(2, 'Please enter a valid last name.')
    .refine(...stringWithContent()),
  owner_1_mobile: z
    .string()
    .refine((value) => !(value && !/^\d{3}-\d{3}-\d{4}$/i.test(value)), 'Invalid, must be 10 digits')
    .refine(...stringWithContent()),
  owner_1_email: z
    .string()
    .email('Please enter a valid email address.')
    .refine(...stringWithContent()),
  owner_1_title: z
    .string()
    .min(2, 'Please enter a valid title.')
    .refine(...stringWithContent()),
  owner_1_street: z
    .string()
    .min(2, 'Please enter a valid street.')
    .refine(...stringWithContent()),
  owner_1_suite: z
    .string()
    .optional()
    .refine(...stringWithContent()),
  owner_1_city: z
    .string()
    .min(2, 'Please enter a valid city.')
    .refine(...stringWithContent()),
  owner_1_state: z
    .string()
    .min(2, 'Please enter a valid state.')
    .refine(...stringWithContent()),
  owner_1_zip: z
    .string()
    .min(2, 'Please enter a valid zip code.')
    .refine(...stringWithContent()),
  owner_dob_day: z.number(),
  owner_dob_month: z.number(),
  owner_dob_year: z.number(),
  owner_ownership: z.number().max(100, 'Ownership percentage must be less or equal to 100.'),
  owner_ssn: SSNSchema.refine(
    (value) => value && /^(\d{3}|\*{3})-(\d{2}|\*{2})-\d{4}$/i.test(value),
    'Invalid, must be xxx-xx-xxxx format'
  ).refine(...stringWithContent()),
  business_years: z.number(),
  business_revenue: z.number().positive(),
  business_phone: z
    .string()
    .refine((value) => !(value && !/^\d{3}-\d{3}-\d{4}$/i.test(value)), 'Invalid, must be 10 digits'),
  business_street: z
    .string()
    .min(2, 'Please enter a valid street.')
    .refine(...stringWithContent()),
  business_city: z
    .string()
    .min(2, 'Please enter a valid city.')
    .refine(...stringWithContent()),
  business_state: z
    .string()
    .min(2, 'Please enter a valid state.')
    .refine(...stringWithContent()),
  business_zip: z
    .string()
    .min(2, 'Please enter a valid zip code.')
    .refine(...stringWithContent()),
  business_suite: z
    .string()
    .optional()
    .refine(...stringWithContent()),
  home_based: z.boolean().optional(),
  // naics_keyword: z.string().min(2, 'Please enter a valid NAICS keyword.'),
  business_naics: z.number(),
  business_structure: z.string().refine(...stringWithContent()),
  business_ein: z
    .string()
    .min(2, 'Please enter a valid ein.')
    .refine(...stringWithContent())
    .refine(
      (value) => value && (/^\d{2}-\d{7}$/i.test(value) || /^\d{9}$/i.test(value)),
      'Invalid, must be 9 digits (xx-xxxxxxx)'
    ),
  business_website: z
    .string()
    .min(2, 'Please enter a valid website.')
    .optional()
    .refine((value) => {
      if (!((value ?? '').trim().length > 0)) {
        return true;
      }

      // Test the schema with various URLs
      // const urls = [
      //   "tento.co",
      //   "https://chatgpt.com/",
      //   "chatgpt.com",
      //   "www.google.com",
      //   "www.google.com/test",
      //   "tento.co/1/2/index.html",
      //   "12.a223.co",
      //   "http://localhost:3000/app/business/more-details",
      //   "sub.domain.example.com",
      //   "http://sub.domain.example.com/path",
      //   "http://web.com:3000/app/business/more-details"
      // ];
      const urlPattern =
        /^(https?:\/\/)?(([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}|localhost)(:\d{1,5})?(\/[a-zA-Z0-9-./]*)?$/;
      return urlPattern.test(value?.trim() ?? '');
    }, 'Please enter a valid website.'),
  equipment_description: z.string().optional(),
};

export const ApplyWizardSteps: Record<ApplyWizardStep, Omit<ApplyWizardStepObject, 'Component'>> = {
  [STEP_HOMEPAGE]: {
    id: STEP_HOMEPAGE,
    isHidden: false,
    isShowingInNavigation: false,
    path: '/',
    zodSchema: z.object({}),
    isComplete: (state) => true,
  },
  [STEP_AMOUNT]: {
    id: STEP_AMOUNT,
    isHidden: false,
    isShowingInNavigation: false,
    path: '/app/amount',
    zodSchema: z.object({
      loan_amount: FIELD_VALIDATION.loan_amount,
      loan_terms: FIELD_VALIDATION.loan_terms,
    }),
    isComplete: (state) => {
      try {
        return !!ApplyWizardSteps[STEP_AMOUNT].zodSchema.parse(state);
      } catch (error) {
        return false;
      }
    },
  },
  [STEP_INFO]: {
    id: STEP_INFO,
    isHidden: false,
    isShowingInNavigation: false,
    path: '/app/info',
    zodSchema: z.object({
      business_name: FIELD_VALIDATION.business_name,
      owner_1_first: FIELD_VALIDATION.owner_1_first,
      owner_1_last: FIELD_VALIDATION.owner_1_last,
      owner_1_mobile: FIELD_VALIDATION.owner_1_mobile,
      owner_1_email: FIELD_VALIDATION.owner_1_email,
      consent: z.boolean().refine((value) => value, 'Consent is required to proceed.'),
    }),
    isComplete: (state) => {
      try {
        return !!ApplyWizardSteps[STEP_INFO].zodSchema.parse(state);
      } catch (error) {
        return false;
      }
    },
  },
  [STEP_VERIFY_LOGIN]: {
    id: STEP_VERIFY_LOGIN,
    isHidden: false,
    isShowingInNavigation: false,
    path: '/app/verify/login',
    zodSchema: z.object({}),
    isComplete: (state) => {
      try {
        return (
          (state.business_name /* previous step is filled anyhow */ ||
            state.business_street) /* next step is filled anyhow */ &&
          !!ApplyWizardSteps[STEP_VERIFY_LOGIN].zodSchema.parse(state)
        );
      } catch (error) {
        return false;
      }
    },
  },
  [STEP_BCM_HOMEPAGE]: {
    id: STEP_BCM_HOMEPAGE,
    isHidden: false,
    isShowingInNavigation: false,
    path: '/bcm',
    zodSchema: z.object({}),
    isComplete: (state) => true,
  },
  [STEP_BCM_SUMMARY]: {
    id: STEP_BCM_SUMMARY,
    isHidden: false,
    isShowingInNavigation: false,
    path: '/bcm/app/summary',
    zodSchema: z.object({
      business_name: FIELD_VALIDATION.business_name,
      business_ein: FIELD_VALIDATION.business_ein,
    }),
    isComplete: (state) => true,
  },
  [STEP_BCM_INFO]: {
    id: STEP_BCM_INFO,
    isHidden: false,
    isShowingInNavigation: false,
    path: '/bcm/app/info',
    zodSchema: z.object({
      business_name: FIELD_VALIDATION.business_name,
      owner_1_first: FIELD_VALIDATION.owner_1_first,
      owner_1_last: FIELD_VALIDATION.owner_1_last,
      owner_1_mobile: FIELD_VALIDATION.owner_1_mobile,
      owner_1_email: FIELD_VALIDATION.owner_1_email,
      consent: z.boolean().refine((value) => value, 'Consent is required to proceed.'),
    }),
    isComplete: (state) => {
      try {
        return !!ApplyWizardSteps[STEP_INFO].zodSchema.parse(state);
      } catch (error) {
        return false;
      }
    },
  },
  [STEP_INTEGRATED_LOGIN]: {
    id: STEP_INTEGRATED_LOGIN,
    isHidden: false,
    isShowingInNavigation: false,
    path: '/login',
    zodSchema: z.object({}),
    isComplete: (state) => true,
  },
  [STEP_BCM_BUSINESS_MORE_DETAILS]: {
    id: STEP_BCM_BUSINESS_MORE_DETAILS,
    isHidden: false,
    isShowingInNavigation: false,
    path: '/bcm/app/business/more-details',
    zodSchema: z.object({
      business_street: FIELD_VALIDATION.business_street,
      business_suite: FIELD_VALIDATION.business_suite,
      business_city: FIELD_VALIDATION.business_city,
      business_state: FIELD_VALIDATION.business_state,
      business_zip: FIELD_VALIDATION.business_zip,
      business_ein: FIELD_VALIDATION.business_ein,
      owner_1_title: FIELD_VALIDATION.owner_1_title,
    }),
    isComplete: (state) => {
      try {
        return !!ApplyWizardSteps[STEP_BUSINESS_MORE_DETAILS].zodSchema.parse(state);
      } catch (error) {
        return false;
      }
    },
  },
  [STEP_BUSINESS_MORE_DETAILS]: {
    id: STEP_BUSINESS_MORE_DETAILS,
    isHidden: false,
    isShowingInNavigation: true,
    path: '/app/business/more-details',
    zodSchema: z.object({
      business_street: FIELD_VALIDATION.business_street,
      business_suite: FIELD_VALIDATION.business_suite,
      business_city: FIELD_VALIDATION.business_city,
      business_state: FIELD_VALIDATION.business_state,
      business_zip: FIELD_VALIDATION.business_zip,
      home_based: FIELD_VALIDATION.home_based,
      business_website: FIELD_VALIDATION.business_website,
      business_years: FIELD_VALIDATION.business_years,
      business_structure: FIELD_VALIDATION.business_structure,
      business_naics: FIELD_VALIDATION.business_naics,
    }),
    isComplete: (state) => {
      try {
        return !!ApplyWizardSteps[STEP_BUSINESS_MORE_DETAILS].zodSchema.parse(state);
      } catch (error) {
        return false;
      }
    },
  },
  [STEP_BANK_CONNECT]: {
    id: STEP_BANK_CONNECT,
    isHidden: false,
    isShowingInNavigation: true,
    path: '/app/bank-connect',
    zodSchema: z.object({}),
    isComplete: (state) => {
      try {
        return !!ApplyWizardSteps[STEP_BANK_CONNECT].zodSchema.parse(state);
      } catch (error) {
        return false;
      }
    },
  },
  [STEP_BUSINESS_FURTHER_DETAILS]: {
    id: STEP_BUSINESS_FURTHER_DETAILS,
    isHidden: false,
    isShowingInNavigation: true,
    path: '/app/business/further-details',
    zodSchema: z.object({
      business_ein: FIELD_VALIDATION.business_ein,
      business_revenue: FIELD_VALIDATION.business_revenue,
    }),
    isComplete: (state) => {
      try {
        return !!ApplyWizardSteps[STEP_BUSINESS_FURTHER_DETAILS].zodSchema.parse(state);
      } catch (error) {
        return false;
      }
    },
  },
  [STEP_BUSINESS_OWNERS]: {
    id: STEP_BUSINESS_OWNERS,
    isHidden: false,
    isShowingInNavigation: true,
    path: '/app/business/owners',
    zodSchema: {
      parse: (data: { [a: string]: any }) => {
        const ownersCount = getNumberOfOwnersFromFormValues(data);
        let schema: { [a: string]: any } = {};
        for (let i = 1; i <= ownersCount; i++) {
          schema[`owner_${i}_ssn`] = FIELD_VALIDATION.owner_ssn;
          schema[`owner_${i}_title`] = FIELD_VALIDATION.owner_1_title;
          schema[`owner_${i}_ownership`] = FIELD_VALIDATION.owner_ownership;
          schema[`owner_${i}_dob_day`] = FIELD_VALIDATION.owner_dob_day;
          schema[`owner_${i}_dob_month`] = FIELD_VALIDATION.owner_dob_month;
          schema[`owner_${i}_dob_year`] = FIELD_VALIDATION.owner_dob_year;
          schema[`owner_${i}_street`] = FIELD_VALIDATION.owner_1_street;
          schema[`owner_${i}_suite`] = FIELD_VALIDATION.owner_1_suite;
          schema[`owner_${i}_city`] = FIELD_VALIDATION.owner_1_city;
          schema[`owner_${i}_state`] = FIELD_VALIDATION.owner_1_state;
          schema[`owner_${i}_zip`] = FIELD_VALIDATION.owner_1_zip;
          if (i > 1) {
            schema[`owner_${i}_mobile`] = FIELD_VALIDATION.owner_1_mobile;
            schema[`owner_${i}_email`] = FIELD_VALIDATION.owner_1_email;
            schema[`owner_${i}_first`] = FIELD_VALIDATION.owner_1_first;
            schema[`owner_${i}_last`] = FIELD_VALIDATION.owner_1_last;
          }
        }
        return z.object(schema).parse(data);
      },
    } as z.ZodSchema,
    isComplete: (state) => {
      try {
        return !!ApplyWizardSteps[STEP_BUSINESS_OWNERS].zodSchema.parse(state);
      } catch (error) {
        return false;
      }
    },
  },
  [STEP_TYPE_OF_FUNDING]: {
    id: STEP_TYPE_OF_FUNDING,
    isHidden: false,
    isShowingInNavigation: true,
    path: '/app/type-of-funding',
    zodSchema: z.object({
      loan_type: z
        .array(z.string().refine(...stringWithContent()))
        .refine((val) => val && Array.isArray(val) && val.some((item) => item !== 'UNKNOWN'), {
          message: 'Please select at least one product type.',
        }),
    }),
    isComplete: (state) => {
      try {
        return !!ApplyWizardSteps[STEP_TYPE_OF_FUNDING].zodSchema.parse(state);
      } catch (error) {
        return false;
      }
    },
  },
  [STEP_WHEN_FUNDING_NEEDED]: {
    id: STEP_WHEN_FUNDING_NEEDED,
    isHidden: false,
    isShowingInNavigation: true,
    path: '/app/aproximate-timing',
    zodSchema: z.object({
      when_funding_needed: z.string().refine(...stringWithContent()),
    }),
    isComplete: (state) => {
      try {
        return !!ApplyWizardSteps[STEP_WHEN_FUNDING_NEEDED].zodSchema.parse(state);
      } catch (error) {
        return false;
      }
    },
  },
  [STEP_SUMMARY]: {
    id: STEP_SUMMARY,
    isHidden: false,
    isShowingInNavigation: true,
    path: '/app/funding-is-almost-here',
    zodSchema: z.object({
      business_name: FIELD_VALIDATION.business_name,
      business_ein: FIELD_VALIDATION.business_ein,
      business_revenue: FIELD_VALIDATION.business_revenue,
      loan_amount: FIELD_VALIDATION.loan_amount,
      loan_terms: FIELD_VALIDATION.loan_terms,
      business_years: FIELD_VALIDATION.business_years,
    }),
    isComplete: (state) => {
      try {
        return (
          /* previous step is filled anyhow */ state.owner_1_dob_day &&
          state.loan_type &&
          !!ApplyWizardSteps[STEP_HOMEPAGE].zodSchema.parse(state) &&
          !!ApplyWizardSteps[STEP_AMOUNT].zodSchema.parse(state) &&
          !!ApplyWizardSteps[STEP_INFO].zodSchema.parse(state) &&
          !!ApplyWizardSteps[STEP_VERIFY_LOGIN].zodSchema.parse(state) &&
          !!ApplyWizardSteps[STEP_BUSINESS_MORE_DETAILS].zodSchema.parse(state) &&
          !!ApplyWizardSteps[STEP_BUSINESS_FURTHER_DETAILS].zodSchema.parse(state) &&
          !!ApplyWizardSteps[STEP_BUSINESS_OWNERS].zodSchema.parse(state) &&
          !!ApplyWizardSteps[STEP_BANK_CONNECT].zodSchema.parse(state) &&
          !!ApplyWizardSteps[STEP_TYPE_OF_FUNDING].zodSchema.parse(state)
        );
      } catch (error) {
        return false;
      }
    },
  },
};
