import React from 'react';
import { FormPageTopPart } from '@components/_shared/FormPageTopPart/FormPageTopPart';
import { Stack, TentoLogo } from 'shared-components';

export const ErrorPage = () => {
  return (
    <div className="p-[20px]">
      <Stack
        flow={'column'}
        justifyContent="start"
        justifyItems={'start'}
        templateColumns={'1fr'}
        templateRows={undefined}
        fullWidth
      >
        <div>
          <TentoLogo height="35px" width="100%" />
        </div>
      </Stack>
      <div style={{ maxWidth: '1000px' }} className="mt-[40px] w-full h-full mx-auto bg-spark rounded-[20px] p-[40px]">
        <FormPageTopPart
          headingText="Whoops! Something Went Wrong."
          subHeadingText={
            "This could be due to a bad network connection or something else. We keep track of these errors, but feel free to contact us if refreshing doesn't quite fix things."
          }
        />
      </div>
    </div>
  );
};
