import React from 'react';
import { Field } from 'redux-form';
import { formatNumberAsThousands, Text } from 'shared-components';
import { Toggle } from '_fields/inputs';
import { estPaymentByApr } from '@utils/paymentCalculations';
import { getFeatureflags } from '@components/../featureFlags';

const TermSelector = (props) => {
  const {
    termOptions,
    loanType,
    loanAmount,
    updatePayment,
    rate,
    defaultValueWc = 60,
    defaultValueEq = 60,
    withMonthlyPayment = true,
    loan_terms,
    config,
  } = props;
  const flags = getFeatureflags();

  if (loanType === 'EQ') {
    return (
      <Field
        component={Toggle}
        onValueChange={(value) => {
          window.setTimeout(() => updatePayment(), 0);
        }}
        valueAsNumber
        type="single"
        name="loan_terms"
        className=""
        defaultValue={defaultValueEq ? Number(defaultValueEq) : undefined}
        buttons={termOptions.map((term) => ({ text: `${term}mo.`, value: Number(term) }))}
        afterSelectedButton={
          withMonthlyPayment && config && flags.applyShowTermPaymentEstimate
            ? (button) => (
                <Text variant="detail-numbers" className="text-center">
                  {formatNumberAsThousands(estPaymentByApr(loanAmount, button.value, config['EQ'].rate), 2)}
                  /mo*
                </Text>
              )
            : undefined
        }
      />
    );
  } else {
    return (
      <Field
        component={Toggle}
        onValueChange={(value) => {
          window.setTimeout(() => updatePayment(), 0);
        }}
        valueAsNumber
        type="single"
        name="loan_terms"
        defaultValue={defaultValueWc ? Number(defaultValueWc) : undefined}
        buttons={termOptions.map((term) => ({ text: `${term}mo.`, value: Number(term) }))}
        afterSelectedButton={
          withMonthlyPayment && config && flags.applyShowTermPaymentEstimate
            ? (button) => (
                <Text variant="detail-numbers" className="text-center">
                  {formatNumberAsThousands(estPaymentByApr(loanAmount, button.value, config['WC'].rate), 2)}
                  /mo*
                </Text>
              )
            : undefined
        }
      />
    );
  }
};

export default TermSelector;
