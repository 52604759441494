import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { formValueSelector } from 'redux-form';
import { ErrorPage } from '@components/_shared/Error/ErrorPage';

const isSyntheticTest = window._DATADOG_SYNTHETICS_BROWSER !== undefined;

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });

    (async () => {
      try {
        if (!isSyntheticTest && error) {
          analytics.track('Apply Error Boundary error', {
            error,
            errorInfo,
          });
          console.error('[ErrorBoundary] componentDidCatch: ', error, errorInfo);
        }
      } catch (e) {
        console.error('ErrorBoundary try-catch failed: ', e);
      }
    })();
  }

  render() {
    if (this.state.error) {
      const { email, first, last } = this.props;

      //render fallback UI
      return <ErrorPage />;
    } else {
      return this.props.children;
    }
  }
}

const selector = formValueSelector('application');

const mapStateToProps = (state) => {
  return {
    business_id: selector(state, 'business_id'),
    contact_id: selector(state, 'owner_1_id'),
    mobile: selector(state, 'owner_1_mobile'),
    email: selector(state, 'owner_1_email'),
    first: selector(state, 'owner_1_first'),
    last: selector(state, 'owner_1_last'),
  };
};

ErrorBoundary = connect(mapStateToProps)(ErrorBoundary);

export default withRouter(ErrorBoundary);
