import React, { ComponentPropsWithoutRef, FC, useState } from 'react';
import { connect } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { RootState } from '@store/reducers';
import { Spinner } from '@components/Spinner/Spinner';
import { FormPageTopPart } from '@components/_shared/FormPageTopPart/FormPageTopPart';
import { ELEMENTS_GAP } from '@utils/spacing';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { currency, normFedTaxId, words } from '@components/_fields/normalizers';
import { updateLoaneeDeal } from '../../../api/updateLoaneeDeal';
import { DataError } from '../../../api/DataError';
import Alert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';
import {
  Separator,
  Stack,
  Text,
  List,
  Pill,
  useResponsiveBreakpoints,
  Button,
  Card,
  ArrowRight,
  StepCard,
  cn,
} from 'shared-components';
import { PlaidConnectCardContainer } from '@components/Apply/BankConnect/PlaidConnectCard';
import { AVAILABLE_TERM_OPTIONS, setTemp } from '@store/actions';
import { StatementsUpload } from './StatementsUpload';
import { ENDPOINT_SAVE_FILE } from '../../../api/fileSave';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';
import { useApplyWizardContext } from '../../../context/ApplyWizardContext';

import { formValueSelector, reduxForm } from 'redux-form';
import { muiCustomSelect, newThemeInput } from '_fields/inputs';
import { Field } from 'redux-form';
import { Form } from '@components/shadcn/components/ui/form';
import { ApplyWizardSteps, STEP_SUMMARY } from '@components/ApplyWizard/ApplyWizardSteps';
import { reduxFormZodValidate } from '@utils/reduxFormZodValidate';
import { useForm } from 'react-hook-form';
import { parseCurrency } from '@components/_fields/parsers';
import { LoanType } from 'src/js/types';
import { required } from '@components/_fields/validation';
import withOnSubmit from '@components/Apply/withOnSubmit/withOnSubmit';
import { isSyntheticTest } from '@utils/platformBasedInfo';

const UploadComponent: any = StatementsUpload;

export type FundingIsAlmostHereProps = {
  partnerCustomFields: any;
  vendor_url: string;
  ownerEmail: string;
  loanAmount: string;
  loan_id: string;
  business_id: string;
  loanType: LoanType;
  loanTerms: string;
  businessName: string;
  businessRevenue: string;
  yearsInBusiness: string;
  handleSubmit: () => void;
  ein: string;
  invalid: boolean;
  isComplete: boolean;
  validationResult: {
    [key: string]: string;
  };
  user: RootState['user'];
  vendor_name: string;
  typeOfStatements: string;
  haveStatements: boolean;
  owner1First?: string;
  loan_amount: string;
  ownerOneId?: string;
  match: any;
  config: RootState['config'];
  saving?: boolean;
};

export type PillInputProps = {
  labelProps?: ComponentPropsWithoutRef<typeof Text>;
  stackProps?: ComponentPropsWithoutRef<typeof Stack>;
  fieldProps?: ComponentPropsWithoutRef<typeof newThemeInput>;
};

export const AmountPillInput = ({ stackProps, labelProps, fieldProps }: PillInputProps) => {
  return (
    <Stack flow="row" gapPx="10px" {...stackProps}>
      <Text variant="label-info" {...labelProps} className={cn('text-center text-[20px]', labelProps?.className ?? '')}>
        Amount
      </Text>
      <div>
        <Field
          name="loan_amount"
          label="Amount (USD)"
          component={newThemeInput}
          className="amount h-[65px] !max-w-[150px] text-[25px] !rounded-[100px] text-center"
          placeholder="Enter Amount"
          formatAsNumber
          parse={parseCurrency}
          data-testid="loan-amount"
          {...fieldProps}
        />
      </div>
    </Stack>
  );
};

export const BusinessNamePillInput = ({ stackProps, labelProps, fieldProps }: PillInputProps) => {
  return (
    <Stack flow="row" gapPx="10px" {...stackProps}>
      <Text variant="label-info" {...labelProps} className={cn('text-center text-[20px]', labelProps?.className ?? '')}>
        Business Name
      </Text>
      <div>
        <Field
          name="business_name"
          component={newThemeInput}
          type="text"
          placeholder="Company Name*"
          normalize={words}
          label="Business Name*"
          data-testid="business_name"
          className="h-[65px] !max-w-[350px] text-[25px] !rounded-[100px] text-center"
        />
      </div>
    </Stack>
  );
};

export const TaxIdPillInput = ({ stackProps, labelProps, fieldProps }: PillInputProps) => {
  return (
    <Stack flow="row" gapPx="10px" {...stackProps}>
      <Text variant="label-info" {...labelProps} className={cn('text-center text-[20px]', labelProps?.className ?? '')}>
        Tax ID
      </Text>
      <div>
        <Field
          data-testid="fed-tax-id"
          name="business_ein"
          component={newThemeInput}
          type="tel"
          label="Tax ID"
          placeholder="Federal Tax ID* (XX-XXXXXXX)"
          normalize={normFedTaxId}
          className="h-[65px] !max-w-[180px] text-[25px] !rounded-[100px] text-center"
        />
      </div>
    </Stack>
  );
};

export const RevenuePillInput = ({ stackProps, labelProps, fieldProps }: PillInputProps) => {
  return (
    <Stack flow="row" gapPx="10px" {...stackProps}>
      <Text variant="label-info" {...labelProps} className={cn('text-center text-[20px]', labelProps?.className ?? '')}>
        Revenue
      </Text>
      <div>
        <Field
          name="business_revenue"
          component={newThemeInput}
          labelText="Gross Revenue*"
          parse={parseCurrency}
          formatAsNumber
          labelClassName="infoFormCustomLabel"
          data-testid="business_revenue"
          className="h-[65px] !max-w-[200px] text-[25px] !rounded-[100px] text-center"
        />
      </div>
    </Stack>
  );
};

export const TermsPillInput = ({
  stackProps,
  labelProps,
  loanType,
  config,
}: PillInputProps & { loanType?: LoanType; config: any }) => {
  // TODO: replace AVAILABLE_TERM_OPTIONS with termOptions but it only supports WC and EQ right now.
  return (
    <Stack flow="row" gapPx="10px" {...stackProps}>
      <Text variant="label-info" {...labelProps} className={cn('text-center text-[20px]', labelProps?.className ?? '')}>
        Terms
      </Text>
      <div>
        <Field
          name="loan_terms"
          component={muiCustomSelect}
          autoFocus
          data-testid="terms_select"
          selectTriggerProps={{ className: 'h-[65px] !max-w-[250px] text-[25px] !rounded-[100px] justify-center' }}
          selectValueProps={{ placeholder: 'Terms*' }}
          valueAsNumber
          options={AVAILABLE_TERM_OPTIONS.map((term) => ({
            text: `${term} months`,
            value: Number(term),
          }))}
        />
      </div>
    </Stack>
  );
};

export const TimeInBusinessPillInput = ({ stackProps, labelProps }: PillInputProps) => {
  return (
    <Stack flow="row" gapPx="10px" {...stackProps}>
      <Text variant="label-info" {...labelProps} className={cn('text-center text-[20px]', labelProps?.className ?? '')}>
        Time in Business
      </Text>
      <div>
        <Field
          name="business_years"
          component={muiCustomSelect}
          label="Years in business*"
          className="w100 mui-input-custom mui-select tib-select"
          autoFocus
          valueAsNumber
          data-testid="business_years"
          selectValueProps={{ placeholder: 'Years in Business' }}
          selectTriggerProps={{ className: 'h-[65px] !max-w-[250px] text-[25px] !rounded-[100px] justify-center' }}
          options={[
            { value: 0, text: 'under 1 year' },
            { value: 1, text: '1 year' },
            { value: 2, text: '2 years' },
            { value: 3, text: '3 years' },
            { value: 4, text: '4 years' },
            { value: 5, text: '5 years' },
            { value: 6, text: '6 years' },
            { value: 7, text: '7 years' },
            { value: 8, text: '8 years' },
            { value: 9, text: '9 years' },
            { value: 10, text: '10 years' },
            { value: 11, text: '11 years' },
            { value: 12, text: '12 years' },
            { value: 13, text: '13 years' },
            { value: 14, text: '14 years' },
            { value: 15, text: '15 years' },
            { value: 16, text: '16 years' },
            { value: 17, text: '17 years' },
            { value: 18, text: '18 years' },
            { value: 19, text: '19 years' },
            { value: 20, text: '20+ years' },
          ]}
        />
      </div>
    </Stack>
  );
};

const FundingIsAlmostHereWrapper1: FC<FundingIsAlmostHereProps> = (props) => {
  const auth0 = useAuth0();
  console.log('FundingIsAlmostHereWrapper1 props', props);

  if (auth0.isLoading) {
    return (
      <div className="app-section slide infoSLide">
        <Spinner />
      </div>
    );
  }

  return <FundingIsAlmostHereWrapper2 {...props} />;
};

export const FundingIsAlmostHereWrapper2: FC<FundingIsAlmostHereProps> = ({
  handleSubmit,
  partnerCustomFields,
  vendor_url,
  ownerEmail,
  business_id,
  loan_id,
  loanAmount,
  loanType,
  loanTerms,
  businessName,
  businessRevenue,
  yearsInBusiness,
  saving: savingOnSubmit,
  vendor_name,
  user,
  ein,
  typeOfStatements,
  haveStatements,
  owner1First,
  loan_amount,
  ownerOneId,
  match,
  config,
  invalid,
  validationResult,
  isComplete,
}) => {
  const rhfForm = useForm();

  const loanTypeSafe = Array.isArray(loanType)
    ? loanType.filter((lt) => lt !== 'UNKNOWN').length > 0
      ? loanType
          .filter((lt) => lt !== 'UNKNOWN')[0]
          .toString()
          .toUpperCase()
      : 'UNKNOWN'
    : loanType?.toString().toUpperCase() ?? 'UNKNOWN';
  const { nextRoute, callApi, dataLayerPush, showProcessingModal, saving } = useApplyWizardContext();
  const auth0 = useAuth0();
  const history = useHistory();
  const [{ data: dealUpdateResponse, loading: dealUpdateLoading, error: dealUpdateError }, updateOpportunity] =
    updateLoaneeDeal();
  const [uploadSelected, setUploadSelected] = useState(false);
  const dispatch = useDispatch();

  const isPlaidConnected = typeOfStatements === 'plaid';
  const areStatementsUploaded = typeOfStatements === 'upload';
  const willEmailStatements = typeOfStatements === 'email';
  const responsiveBreakpoints = useResponsiveBreakpoints();

  return (
    <div className="mb-[30px]">
      <Helmet>
        <title>Summary of your application</title>
      </Helmet>
      <Form {...rhfForm}>
        <Stack gap={ELEMENTS_GAP} className="decision">
          <FormPageTopPart
            headingText="Funding is Almost Here"
            subHeadingText="Well done, so far everything looks great. Please review the details below, and when you're ready, submit your application."
          />

          <Separator />

          <Text variant="subheadline-lg" className="text-center">
            Key Business Details for Review
          </Text>
          <Stack gapPx="35px">
            <form onSubmit={handleSubmit}>
              <List
                containerProps={{
                  className: 'align-center justify-center',
                }}
                itemContainerProps={{
                  className: 'basis-0 flex-initial',
                }}
              >
                <AmountPillInput />
                <TermsPillInput loanType={loanTypeSafe} config={config} />
                <BusinessNamePillInput />
              </List>
              <Stack gapPx="60px" className="decision">
                <List
                  containerProps={{
                    className: 'align-center justify-center',
                  }}
                  itemContainerProps={{
                    className: 'basis-0 flex-initial',
                  }}
                >
                  <TaxIdPillInput />
                  <TimeInBusinessPillInput />
                  <RevenuePillInput />
                  <Pill
                    variant={isPlaidConnected || areStatementsUploaded ? 'completed' : 'incomplete'}
                    label="Bank Account"
                    buttonProps={{
                      className: 'text-[25px]',
                    }}
                    value={
                      isPlaidConnected ? 'Connected' : areStatementsUploaded ? 'Statements Uploaded' : 'Not Connected'
                    }
                  />
                </List>

                {(areStatementsUploaded || willEmailStatements) && (
                  <Stack gapPx="35px">
                    <Text variant="subheadline-lg" className="text-center">
                      Next Steps for Submission
                    </Text>
                    {dealUpdateError && (
                      <Alert severity="error">
                        <AlertTitle>Failed to save</AlertTitle>
                        <DataError msg="Unfortunately, we couldn't save your update." error={dealUpdateError} />
                      </Alert>
                    )}
                    <Stack alignItems="center" justifyContent="center" fullWidth>
                      <Card
                        size="wide"
                        className={cn('flex', {
                          ['!w-full']: responsiveBreakpoints.isMobile || responsiveBreakpoints.isSmallDesktop,
                        })}
                      >
                        <div className="flex flex-col justify-between w-full">
                          <Stack flow="row" justifyContent="space-between" justifyItems="stretch">
                            <Text variant="headline-sm">Ready to grow?</Text>
                            <Text variant="headline-sm">Submit your application.</Text>
                          </Stack>
                          <div className="max-w-[415px]">
                            <Text variant="body-general">
                              Once submitted, your application will be reviewed and you will hear from us within 1-2
                              business days.
                            </Text>
                          </div>
                          <Stack flow="column" justifyContent="space-between" justifyItems="stretch">
                            <Button
                              variant="primary-green"
                              type="submit"
                              disabled={dealUpdateLoading || saving || invalid}
                              isLoading={dealUpdateLoading || saving || savingOnSubmit}
                            >
                              {invalid ? 'Fill in Business Details to Submit' : 'Submit'}
                            </Button>
                            <Button
                              variant="cta-small-secondary"
                              type="submit"
                              className="p-0 w-[50px] h-[50px]"
                              disabled={dealUpdateLoading || saving || invalid}
                            >
                              <ArrowRight />
                            </Button>
                          </Stack>
                        </div>
                      </Card>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </form>
            {!(areStatementsUploaded || willEmailStatements) && (
              <Stack flow="row">
                {!uploadSelected && !isPlaidConnected ? (
                  <Stack gapPx="35px">
                    <Text variant="subheadline-lg" className="text-center">
                      Next Steps for Submission
                    </Text>
                    <Stack flow="column" gapPx="10px">
                      <Stack
                        alignItems="start"
                        justifyContent={
                          responsiveBreakpoints.isSmallDesktop || responsiveBreakpoints.isDesktop ? 'end' : 'center'
                        }
                      >
                        <PlaidConnectCardContainer
                          {...{
                            user,
                            callApi,
                            history,
                            vendor_name,
                            loan_id,
                            loan_amount,
                            owner1First,
                            dataLayerPush,
                            business_id,
                            first: owner1First,
                            showProcessingModal,
                            setTemp,
                            match,
                            nextRoute,
                            customConnectEndpoint: undefined,
                            customLinkTokenEndpoint: undefined,
                            customOnConnect: () => {},
                          }}
                        />
                      </Stack>
                      {(responsiveBreakpoints.isSmallDesktop || responsiveBreakpoints.isDesktop) && (
                        <Stack alignItems="start" justifyItems="start" justifyContent="start" className="relative">
                          <div>
                            <Button
                              variant="cta-small-primary"
                              className="absolute top-[140px] left-[-30px] p-0 w-[50px] h-[50px] z-10 font-condensed hover:bg-spark pointer-events-none"
                            >
                              OR
                            </Button>
                          </div>
                          <StepCard
                            type="current"
                            headline="Upload Documents"
                            textBody="Upload the last 4 months of business bank statements for review."
                            cardProps={{ className: 'w-full justify-between' }}
                            buttonProps={{
                              onClick: () => {
                                setUploadSelected(true);
                              },
                              children: 'Upload',
                              className: 'font-condensed',
                            }}
                            button="Upload"
                          />
                        </Stack>
                      )}
                    </Stack>
                  </Stack>
                ) : (
                  <Stack flow="row" gapPx="35px">
                    {isPlaidConnected && (
                      <Alert severity="error">
                        <AlertTitle>
                          Thank you for attempting to connect your bank account, unfortunately the request was not
                          successful. Can you please upload the last 4 months of your business bank statements for
                          further review? Thank you!
                        </AlertTitle>
                      </Alert>
                    )}

                    <UploadComponent
                      {...{ loan_type: loanTypeSafe, first: owner1First, loan_id, loan_amount }}
                      dataLayerPush={dataLayerPush}
                      setTemp={setTemp}
                      withOtherOptions={false}
                      allowSkip={false}
                      allowPlaid={!isPlaidConnected}
                      openAlert={() => {}}
                      closeAlert={() => {}}
                      withDealUpdate={false}
                      dataMapper={(files: any) => {
                        return {
                          files,
                          dealId: loan_id,
                          accountId: business_id,
                          contactId: ownerOneId,
                        };
                      }}
                      customEndpoint={`${ENDPOINT_SAVE_FILE}-continue`}
                      customOnSaved={() => {
                        dispatch(change('application', 'have_statements', true));
                        dispatch(change('application', 'type_of_statements', 'upload'));
                        if (!isSyntheticTest) {
                          analytics.track('User Upload Docs', {
                            source: 'apply-wizard-standard-flow',
                          });
                        }
                      }}
                      onPlaid={() => {
                        setUploadSelected(false);
                      }}
                      onSkip={() => {
                        dispatch(change('application', 'have_statements', false));
                        dispatch(change('application', 'type_of_statements', 'email'));
                        (async () => {
                          if (!isSyntheticTest) {
                            analytics.track('Apply Form - Skip Connect');
                          }
                        })();
                      }}
                    />
                  </Stack>
                )}

                <Button
                  variant="text"
                  data-testid="btn-send-via-email"
                  onClick={() => {
                    dispatch(change('application', 'have_statements', false));
                    dispatch(change('application', 'type_of_statements', 'email'));
                    (async () => {
                      if (!isSyntheticTest) {
                        analytics.track('Apply Form - Skip Connect');
                      }
                    })();
                  }}
                  className="text-center font-condensed uppercase underline"
                >
                  Skip For Now
                </Button>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Form>
    </div>
  );
};

const FundingIsAlmostHereFormWrapper = reduxForm<FundingIsAlmostHereProps, FundingIsAlmostHereProps>({
  form: 'application',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: reduxFormZodValidate(ApplyWizardSteps[STEP_SUMMARY].zodSchema),
})(FundingIsAlmostHereWrapper1);

const appSelector = formValueSelector('application');

const mapStateToProps = (state: RootState) => {
  return {
    isComplete: ApplyWizardSteps[STEP_SUMMARY].isComplete(state.form?.application?.values as any),
    formValues: state.form?.application?.values,
    validationResult: reduxFormZodValidate(ApplyWizardSteps[STEP_SUMMARY].zodSchema)(state.form?.application?.values),
    business_id: appSelector(state, 'business_id'),
    loan_id: appSelector(state, 'loan_id'),
    partnerCustomFields: state.partnerCustomFields,
    vendor_url: state.brand?.data?.vendor_url,
    ownerOneId: appSelector(state, 'owner_1_id'),
    ownerEmail: appSelector(state, 'owner_1_email'),
    loanAmount: appSelector(state, 'loan_amount'),
    loanType: appSelector(state, 'loan_type') || 'UNKNOWN',
    loanTerms: appSelector(state, 'loan_terms'),
    businessName: appSelector(state, 'business_name'),
    businessRevenue: appSelector(state, 'business_revenue'),
    yearsInBusiness: appSelector(state, 'business_years'),
    ein: appSelector(state, 'business_ein'),
    haveStatements: appSelector(state, 'have_statements'),
    typeOfStatements: appSelector(state, 'type_of_statements'),
    vendor_name: state.brand?.data?.vendor_name,

    owner1First: appSelector(state, 'owner_1_first'),
    loan_amount: appSelector(state, 'loan_amount'),
    config: state.config,

    user: state.user,
  };
};

export const FundingIsAlmostHere = withOnSubmit(connect(mapStateToProps)(FundingIsAlmostHereFormWrapper), STEP_SUMMARY);
