import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { formValueSelector, change } from 'redux-form';
import { checkUser } from '@utils/reduxUtils';
import { estPaymentByApr } from '@utils/paymentCalculations';
import Amount from './Amount.jsx';
import { isSyntheticTest } from '../../../utils/platformBasedInfo.js';
import { useAuth0 } from '@auth0/auth0-react';
import { getCountryByIP } from '../../../api/getCountryByIP';
import { getFeatureflags } from '@components/../featureFlags';
import { updateLoaneeDeal } from '../../../api/updateLoaneeDeal';
import { currencyToDecimal } from '../../../utils/currencyToDecimal';
import { useApplyWizardContext } from '../../../context/ApplyWizardContext';

const AmountContainer = ({
  user,
  match,
  history,
  dispatch,
  businessId,
  opportunityId,
  loanAmount,
  loanTerms,
  loanType,
  config,
  vendor_name,
  ...other
}) => {
  const loanTypeSafe = Array.isArray(loanType)
    ? loanType.filter((lt) => lt !== 'UNKNOWN').length > 0
      ? loanType
          .filter((lt) => lt !== 'UNKNOWN')[0]
          .toString()
          .toUpperCase()
      : 'UNKNOWN'
    : loanType?.toString().toUpperCase() ?? 'UNKNOWN';
  const { nextRoute, callApi, setSaving, saving } = useApplyWizardContext();
  const [showBanner, setShowBanner] = useState(false);
  const flags = getFeatureflags();
  const { isAuthenticated, logout } = useAuth0();
  const [{ data: countryByIpResponse, loading: countryByIpLoading, error: countryByIpError }, checkCountryByIp] =
    getCountryByIP();
  const [{ data: dealUpdateResponse, loading: dealUpdateLoading, error: dealUpdateError }, updateOpportunity] =
    updateLoaneeDeal();

  useEffect(() => {
    if (flags.applyNonUsNoticeEnabled) {
      checkCountryByIp();
    }
  }, [flags]);

  useEffect(() => {
    if (countryByIpResponse) {
      if (countryByIpResponse.cfIpCountry?.toLowerCase() === 'us') {
        setShowBanner(false);
      } else {
        setShowBanner(true);
      }
    }
  }, [countryByIpResponse]);

  // }
  useEffect(() => {
    // check if user login is expired
    checkUser(user, match, dispatch, history, logout);
  }, []);
  const updatePayment = () => {
    const paymentAmount = estPaymentByApr(loanAmount, loanTerms, config[loanTypeSafe?.toUpperCase()]?.rate);

    dispatch(change('application', 'loan_payment', paymentAmount));
  };

  const saveAmount = async (form) => {
    setSaving(true);
    if (!isSyntheticTest) {
      analytics.track('Apply Form - Amount', {
        amount: loanAmount,
      });
    }

    updatePayment();
    if (isAuthenticated && businessId && opportunityId) {
      await updateOpportunity({
        data: {
          loanRequest: {
            amount: currencyToDecimal(loanAmount),
            termLength: loanTerms,
            termUnit: 'month',
          },
        },
      });
    }
    nextRoute();
    setSaving(false);
  };

  return (
    <Amount
      showBanner={showBanner && flags.applyNonUsNoticeEnabled}
      onSubmit={saveAmount}
      updatePayment={updatePayment}
      {...{
        callApi,
        user,
        match,
        history,
        dispatch,
        setSaving,
        nextRoute,
        loanAmount,
        loanTerms,
        loanType: loanTypeSafe,
        config,
        saving,
        ...other,
      }}
    />
  );
};

const selector = formValueSelector('application');

const mapStateToProps = (state) => {
  return {
    opportunityId: selector(state, 'loan_id'),
    businessId: selector(state, 'business_id'),
    loanAmount: selector(state, 'loan_amount'),
    loanType: selector(state, 'loan_type') || ['UNKNOWN'],
    loanTerms: selector(state, 'loan_terms'),
    equipment: selector(state, 'equipment_description'),
    first: selector(state, 'owner_1_first'),
    config: state.config,
    user: state.user,
    vendor_name: state.brand?.data?.vendor_name,
  };
};

const AmountWrapped = connect(mapStateToProps)(withRouter(AmountContainer));

export default AmountWrapped;
