import '../Bank/Upload.scss';
import '../Bank/Bank.scss';
import 'filepond/dist/filepond.min.css';
import React, { Component, createRef, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { Link } from 'react-router-dom';
import { FilePond } from 'react-filepond';
import ContinueButton from '@components/_shared/Continue/Button';
import { FormPageTopPart } from '../../_shared/FormPageTopPart/FormPageTopPart';
import { backendBaseUrl, backendUrl, isSyntheticTest } from '../../../utils/platformBasedInfo';
import { useForm } from 'react-hook-form';
import { Form } from '@components/shadcn/components/ui/form';
import { Stack } from '@components/Stack/Stack';
import { ELEMENTS_GAP } from '@utils/spacing';
import { generateNanoID } from '@utils/nanoId';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getFeatureflags } from '@components/../featureFlags';
import { updateLoaneeDeal } from '../../../api/updateLoaneeDeal';
import { DataError } from '../../../api/DataError';
import Alert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';
import { fileSave } from '../../../api/fileSave';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';
import { Button, Text } from 'shared-components';

const StatementsUpload2 = ({
  openAlert,
  closeAlert,
  saving,
  business_id,
  loan_id,
  contact_id,
  first,
  dataLayerPush,
  setTemp,
  withOtherOptions = true,
  customOnSaved,
  customEndpoint,
  withDealUpdate = true,
  withHeading = true,
  withSecurityText = true,
  withSubHeading = true,
  isUploadOnly = false,
  allowSkip = true,
  allowPlaid = true,
  onSkip,
  onPlaid,
  dataMapper,
}) => {
  const { plaidConnect } = getFeatureflags();
  const rhfForm = useForm();
  const history = useHistory();
  const [files, setFiles] = useState([]);
  const [invalid, setInvalid] = useState(true);
  const pond = useRef(null);
  const [{ data: dealUpdateResponse, loading: dealUpdateLoading, error: dealUpdateError }, updateOpportunity] =
    updateLoaneeDeal();
  const [{ data: fileSaveResponse, loading: fileSaveLoading, error: fileSaveError }, saveFile] = fileSave();
  const dispatch = useDispatch();

  useEffect(() => {
    if (fileSaveResponse) {
      if (withDealUpdate) {
        const dealFormattedBody = {
          business: {
            id: business_id,
          },
          loanRequest: {
            id: loan_id,
            bankStatements: 'upload',
          },
        };
        updateOpportunity({
          data: dealFormattedBody,
        });
      } else {
        if (customOnSaved) {
          customOnSaved();
        }
      }
    }
  }, [fileSaveResponse]);

  useEffect(() => {
    if (dealUpdateResponse) {
      if (customOnSaved) {
        customOnSaved();
      } else {
        if (!isSyntheticTest) {
          dataLayerPush('manual-statements', {
            loan_id: loan_id,
            first: first,
            property: 'upload',
          });
        }

        dispatch(change('application', 'have_statements', true));
        dispatch(change('application', 'type_of_statements', 'upload'));
        setTemp({
          haveStatements: true,
        });
        history.push('/app/summary');
      }
    }
  }, [dealUpdateResponse]);

  const handleError = (error, file) => {
    let msg = error.message || error.body;

    switch (error.code) {
      case 403:
        msg = 'Invalid token account combination';
        break;
      case 413:
        msg = 'File too large. Max file size is 8MB';
        break;
      case 415:
        msg = 'Uploaded files must be .jpg, .jpeg, .png, or .pdf';
        break;
      case 422:
        msg = 'Upload was missing required account number';
        break;
    }

    openAlert(msg, 'info', false, false);
  };

  const onUploadComplete = (error, file) => {
    if (error === null) {
      closeAlert();
      setFiles((files) => {
        setInvalid(files.length === 0);
        return files;
      });
    } else {
      console.log('error', error);
    }
  };

  const filepondServer = {
    process: {
      url: `${backendBaseUrl}/files/upload`,
    },
    revert: null,
    fetch: null,
    remove: null,
  };

  const serverForMocking = {
    process: () => {
      axios.post(`${backendBaseUrl}/bank/upload`, { test: 'test text' });
    },
    revert: null,
    fetch: null,
    remove: null,
  };

  const onSaveFiles = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    // Trigger the upload manually
    if (pond.current) {
      await pond.current.processFiles();
    }

    const pondFiles = (await pond.current?.getFiles()) ?? [];
    const fileArray = pondFiles.map((fileItem) => {
      const file = fileItem.file;
      const metadata = fileItem.getMetadata();

      return {
        name: file.name,
        type: file.type,
        size: file.size,
        lastModified: file.lastModified,
        metadata: {
          ...metadata,
          dealId: loan_id,
        },
      };
    });

    saveFile({
      data: dataMapper ? dataMapper(fileArray) : fileArray,
      ...(customEndpoint ? { url: customEndpoint } : {}),
    });
  };

  return (
    <Form {...rhfForm}>
      <div className="app-section slide uploadSlide ">
        <Stack gap={ELEMENTS_GAP}>
          {withHeading && (
            <Text variant="subheadline-lg" className="text-center">
              Upload Statements
            </Text>
          )}
          {withSubHeading && (
            <Text variant="subheadline-sm" className="text-center">
              Please upload the last 4 months business bank statements below.
            </Text>
          )}
          <div className="file-upload">
            <FilePond
              data-testid="bank-statemnet-file-upload"
              ref={pond}
              files={files}
              name="document"
              allowMultiple
              maxFiles={50}
              instantUpload={true}
              server={process.env.NODE_ENV === 'test' ? serverForMocking : filepondServer}
              onaddfile={(error, file) => {
                // From Volt:
                file.setMetadata('client-identifier', generateNanoID());
                file.setMetadata('source-platform', 'volt');
                file.setMetadata('source-url', window.location.href);
                file.setMetadata('record-id', loan_id);
                file.setMetadata('record-type', 'opportunity');
                file.setMetadata('document-type', 'any');

                // Apply
                file.setMetadata('docType', 'stmt');
                file.setMetadata('account_id', business_id);
                file.setMetadata('contact_id', contact_id);
              }}
              onerror={(error, file) => {
                handleError(error, file);
              }}
              labelFileProcessingError={(error) => {
                return error.body;
              }}
              labelIdle={
                files.length === 0
                  ? '<div class="file-upload-label"> <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" className="drop-svg" > <path d="M32 32L24 24L16 32" stroke="#398EF8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> <path d="M24 24V42" stroke="#398EF8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> <path d="M40.7789 36.78C42.7296 35.7165 44.2706 34.0337 45.1587 31.9972C46.0468 29.9607 46.2314 27.6864 45.6834 25.5333C45.1353 23.3803 43.8859 21.471 42.1323 20.1069C40.3787 18.7427 38.2207 18.0014 35.9989 18H33.4789C32.8736 15.6585 31.7453 13.4846 30.1788 11.642C28.6124 9.79927 26.6486 8.33567 24.4351 7.36118C22.2216 6.3867 19.816 5.92669 17.3992 6.01573C14.9823 6.10478 12.6171 6.74057 10.4813 7.8753C8.34552 9.01003 6.49477 10.6142 5.06819 12.5671C3.64161 14.5201 2.67632 16.771 2.2449 19.1508C1.81348 21.5305 1.92715 23.977 2.57737 26.3065C3.22759 28.6359 4.39743 30.7877 5.99895 32.6" stroke="#398EF8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> <path d="M32 32L24 24L16 32" stroke="#398EF8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> </svg> </div><div> Drag & Drop your files or <span className="filepond--label-action">Browse</span></div>'
                  : '<div> Drag & Drop your files or <span className="filepond--label-action">Browse</span></div>'
              }
              onupdatefiles={(fileItems) => {
                setFiles(fileItems.map((fileItem) => fileItem.file));
              }}
              onprocessfile={(error, file) => {
                onUploadComplete(error, file);
              }}
            />
          </div>

          {withSecurityText && (
            <p className="security-msg">
              *We will upload and store your statements using industry standard security practices.
            </p>
          )}
          {dealUpdateError && (
            <Alert severity="error">
              <AlertTitle>Failed to save</AlertTitle>
              <DataError
                msg="Unfortunately, we couldn't save files to your application. Please try again. Make sure you have filled in all of the previous steps."
                error={dealUpdateError}
              />
            </Alert>
          )}
          {fileSaveError && (
            <Alert severity="error">
              <AlertTitle>Failed to save</AlertTitle>
              <DataError
                msg="Unfortunately, we couldn't save files to your application. Please try again."
                error={fileSaveError}
              />
            </Alert>
          )}
          <form onSubmit={onSaveFiles} className="uploadContinueButtonForm">
            <ContinueButton
              data-testid="btn-upload-statement"
              btnText="Save Files"
              invalid={invalid}
              saving={saving || dealUpdateLoading || fileSaveLoading}
            />
          </form>

          {!isUploadOnly && allowPlaid && (
            <Button variant="text" data-testid="btn-connect-bank" onClick={() => onPlaid()} className="text-center">
              Connecting sounds easier, let's do that
            </Button>
          )}

          {!isUploadOnly && allowSkip && (
            <Button variant="text" data-testid="btn-bank-email" onClick={() => onSkip()} className="text-center">
              Skip - I don't have them right now
            </Button>
          )}
        </Stack>
      </div>
    </Form>
  );
};

const selector = formValueSelector('application');

const mapStateToProps = (state) => {
  return {
    business_id: selector(state, 'business_id'),
    loan_id: selector(state, 'loan_id'),
    contact_id: selector(state, 'owner_1_id'),
    email: selector(state, 'owner_1_email'),
    user: state.user,
  };
};

const StatementsUploadWrapped = reduxForm({
  form: 'application',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(StatementsUpload2);

export const StatementsUpload = connect(mapStateToProps)(StatementsUploadWrapped);
